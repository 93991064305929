import React, {useState} from 'react'
import { useNavigate, useParams} from 'react-router-dom';

import { addTemplate, parseTemplate } from '../../../controllers/template'
import { toast } from 'react-toastify'

const AttributeSelect = ({ label, attribute, options, onChange }) => {
   return (
      <li>
         <label htmlFor={attribute}>Correspondência ao: <strong>{label}</strong>:</label>
         <select className='selectBox' name={attribute} id={attribute} onChange={onChange}>
            <option value="">-- Selecione um opção --</option>
            {
               options.map((option) => (
                  <option value={option} key={option}>
                     {option}
                  </option>
               ))
            }
         </select>
      </li>
   )
}


function AddTemplate() {
   const navigate = useNavigate()
   const {idOperator} = useParams()
   const [templateDate, setTemplateDate] = useState()
   const [template, setTemplate] = useState(null)

   //Template Map
   const [columnKeys, setColumnKeys] = useState(null)
   const [id, setId] = useState(null)
   const [name, setName] = useState(null)
   const [quantityBom, setQuantityBom] = useState(null)

   const [buttonPTDisabled,setButtonPTDisabled] = useState(false)
   
   const readFile = (file, callback) => {
      let reader = new FileReader()
      reader.onload = (event) => {
         let fileData = event.target.result
         callback(fileData)
      }
      reader.readAsDataURL(file)
   }
   
   const handleSubmeter = async () => {
      if (template && templateDate && id && name && quantityBom) {
         readFile(template,async (data) => {
            const res = await addTemplate({
               idOperator,
               templateDate,
               templateMap: {
                  id,
                  name,
                  quantityBom,
               },
               templateName: template.name,
               templateContent: data,
            })

            if (res) {
               navigate(`/operator/${idOperator}`)
            }
         })
      } else {
         toast.error("Formulário incompleto!")
      }
   }
   
   const handleParseTemplate = async () => {
      if (template) {
         setButtonPTDisabled(true)

         readFile(template,async (data) => {

            const results = await parseTemplate({
               idOperator,
               fileName: template.name,
               data: data,
            })

            if (results != undefined) {
               setColumnKeys(results)
            }
         })
      } else {
         toast.error("Insira o template!")
      }
   }


   return (
      <>
      <div className='contentTitle'>
         <h2>Adicionar Template</h2>
      </div>
         <div className='form-container'>
            <ul className='form-strait'>
               <li>
                  <label>Template BoM:</label>
                  <input className="inputBox" type="file" onChange={(event) => {setTemplate(event.target.files[0])}} />
               </li>
               <li>
                  <label>Data do Template:</label>
                  <input className="inputBox" type="date" onChange={(event) => {setTemplateDate(event.target.value)}} />
               </li>
            </ul>
         </div>

         <div>
            {
               !columnKeys && (
                  <button className="button" disabled={buttonPTDisabled} onClick={handleParseTemplate}>Processar Template</button>
               )
            }
            {
               columnKeys && (
               <>
                  <h3>Mapeamento de Atributos</h3>
                  <div className='form-container'>
                     <ul className="form-strait">
                        <AttributeSelect
                           label="Id"
                           attribute="id"
                           options={columnKeys}
                           onChange={(e) => setId(e.target.value)}
                        />
                        <AttributeSelect
                           label="Nome dos materiais"
                           attribute="name"
                           options={columnKeys}
                           onChange={(e) => setName(e.target.value)}
                        />
                        <AttributeSelect
                           label="Quantidade BoM"
                           attribute="quantityBom"
                           options={columnKeys}
                           onChange={(e) => setQuantityBom(e.target.value)}
                        />
                     </ul>
                  </div>
                  
                  <button className="button" onClick={handleSubmeter}>Gravar</button>
               </>
            )}
         </div>
      </>
   )
}

export default AddTemplate