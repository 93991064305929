import React, { useContext, useEffect, useState } from 'react'
import DynamicList from '../../../components/DynamicList'
import GeneralContext from  '../../../context/GeneralContext'

function Cable() {
   const [loading,setLoading] = useState(true)
   const { elements: rule, setElements: setRule} = useContext(GeneralContext)

   useEffect(() => {
      if (!rule) {
         setRule({
            cumulate: "false",
            installations: [],
            capacities: []
         })
      }
      setLoading(false)
   }, [])


   if (!loading) {

      const contextVariables = Object.keys(rule).filter(a => a != 'cumulate' &&  a != 'installations').reduce((acc, key) => {
         acc[key] = {
            elements: rule[key],
            setElements: (value) => setRule((prevState) => ({ ...prevState, [key]: value }))
         }
         return acc
      }, {})

      const handleChange = (value, field) => {
         setRule(prevState => ({ ...prevState, [field]: value }))
      }

      return (
         <ul>
            <li>
               <label htmlFor="cumulate">Aplicar ao <strong>acumulado</strong>:</label>
               <select className='inputBox' name="cumulate" id="cumulate" value={rule.cumulate} onChange={(e) => handleChange(e.target.value, 'cumulate')}>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
               </select>
            </li>
            <li>
               <label htmlFor="installations"><strong>Instalação</strong>:</label>
               <select className='inputBox' name="installations" id="installations" value={rule.installations.length > 0 ? rule.installations[0] : ""} onChange={(e) => {handleChange([e.target.value],'installations')}}>
                  <option value="">-- Selecione um opção --</option>
                  <option value="fachada">Fachada</option>
                  <option value="conduta">Conduta</option>
                  <option value="aereo">Aéreo</option>
               </select>
            </li>
            {
            Object.entries(contextVariables).map(([name,context]) =>(
               <GeneralContext.Provider name={name} value={context}>
                  <DynamicList context="rules" name={name}></DynamicList>
               </GeneralContext.Provider>
            ))
            }
         </ul>
      )
   }
}

export default Cable