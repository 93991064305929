import React, {useState,useEffect} from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getMaterialsApplied, getRulesByMaterial, getRules} from '../../../../controllers/rule'
import { getMaterials, getMaterial, getTemplates } from '../../../../controllers/template'

import RuleList from '../../../components/RuleList'
import ButtonTabs from '../../../components/ButtonTabs'
import { useTranslation } from 'react-i18next'


function Materials() {
   const {idOperator} = useParams()
   const {t} = useTranslation()
   const [templates, setTemplates] = useState([])
   const [template, setTemplate] = useState(undefined)

   const [materials, setMaterials] = useState([])
   const [filteredMaterials, setFilteredMaterials] = useState(materials)
   const [material, setMaterial] = useState(
      localStorage.getItem('selectedMaterial') || undefined
   )
   const [rules, setRules] = useState(undefined)
   const [rule, setRule] = useState(
       undefined
   )


   useEffect(_ => {
      loadTemplates()
   },[])

   useEffect(_ => {
      if (template) {
         loadMaterials()
      }
   }, [template])

   useEffect(_ => {
      if (material) {
         loadRules()
      }
   },[material])

   const loadTemplates = async () => {
      const templatesResult = await getTemplates({ 
         idOperator,
      })

      if (templatesResult) {
         for (const k in templatesResult) {
            if (templatesResult[k].isDefault) {
               setTemplate(templatesResult[k].date)
               break
            }
         }

         setTemplates(templatesResult)
      }
   }

   const loadMaterials = async () => {
      const materialsResult = await getMaterialsApplied({
         idOperator,
         templateDate: template
      })


      if (materialsResult != null) {
         setMaterials(materialsResult)
         setFilteredMaterials(materialsResult)
      }
   }

   const loadRules = async () => {
      const rulesResult = await getRulesByMaterial({
         idOperator,
         idMaterial: material
      })

      if (rulesResult != null) {
         setRules(rulesResult)
      }
   }

   const handleFilterRule = (filter) => {
      if (filter == rule) {
         setRule(undefined)
         localStorage.setItem('selectedRule', undefined)
      } else {
         setRule(filter)
         localStorage.setItem('selectedRule', filter)
      }
   }

   const handleSelectedMaterial = (idMaterial) => {
      setMaterial(idMaterial)
      localStorage.setItem('selectedMaterial', idMaterial)
      setRule(undefined)
      localStorage.setItem('selectedRule', undefined)
   }

   const handleSearchMaterials = (e) => {
      const searchValue = e.target.value.toLowerCase()
      setFilteredMaterials(materials.filter(m => m.name.toLowerCase().includes(searchValue)))
  }

   return (
      <>
      <div className="grid-two">
         <div className="grid-column">
            <div className='column-title'>
               <h2>Materiais</h2>
            </div>
            <ul className='form'>
               {
                  templates && (
                     <li className='card'>
                        <label htmlFor="templates"><strong>Template: </strong></label>
                        <select 
                           className={`selectBox ${template ? '' : 'red'}`} 
                           value={template}
                           name="templates"
                           id="templates"
                           onChange={(e) => {setTemplate(e.target.value)}}
                        >
                           <option value="" key="">-- Selecione um template --</option>
                           {
                              templates.map(t => (
                                 <option value={t.date} key={t.date}>{t.date}</option>
                              ))
                           }
                        </select>
                     </li>
                  )
               }

               <li className='card'>
                  <label htmlFor="material"><strong>Material:</strong></label>
                  <div className='container-ver'>
                     <div className='container-ver-item'>
                        <input className="inputBox" type="text" placeholder="Procure um material..." onChange={handleSearchMaterials} />
                     </div>
                     <div className='container-ver-item'>
                        <select 
                           className="selectBox"
                           name="material"
                           id="material"
                           value={material}
                           onChange={(e) => handleSelectedMaterial(e.target.value)}
                        >
                           <option value="">--Selecione o material--</option>
                           {
                              filteredMaterials && filteredMaterials.map(m => (
                                 <option value={m.key} key={m.key}>{m.name}</option>
                              ))
                           }
                        </select>
                     </div>
                  </div>
               </li>
            </ul>

            
         </div>
         <div className="grid-column">
            <div className='column-title'>
               <h2>Regras</h2>
            </div>
            {
               rules && (
                  <>
                     <ButtonTabs
                        filters={['join', 'plc', 'spliter', 'cable', 'post', 'tube', 'trench', 'allocation']}
                        handleFilterClick={handleFilterRule}
                        selectedFilter={rule}
                     />
                     <hr></hr>
                     <div className='container'>
                     {
                        rule ? (
                           rules[rule].length > 0 ? (
                              <RuleList 
                                 idOperator={idOperator}
                                 component={rule}
                                 rules={rules[rule]} 
                              />
                           ) : (
                              <h3>{t(`tabs.${rule}`)}: não possui regras...</h3>
                           )
                        ) : (
                           ['join', 'plc', 'spliter', 'cable', 'post', 'tube', 'trench', 'allocation'].map(k => (
                              <RuleList 
                                 idOperator={idOperator} 
                                 component={k}  
                                 rules={rules[k]}
                              />
                           ))
                        )  
                     }
                     </div>
                  </>
               )
            }
         </div>
      </div>
      </>
   )
}

export default Materials