import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { getRule, getSubRules, postSubRule, deleteSubRule} from '../../../controllers/rule'
import { getMaterial, getTemplates, getTemplate } from '../../../controllers/template'
import RuleSpec from '../../components/RuleSpec'


function SubRule() {
   const {t} = useTranslation()
   const {idOperator, component, idRule} = useParams()

   const [rule,setRule] = useState()
   const [subRules,setSubRules] = useState([])

   const [selectedOption, setSelectedOption] = useState('no')
   const [applyMeters,setApplyMeters] = useState(0)

   const [templates, setTemplates] = useState([])
   const [template, setTemplate] = useState('')
   
   const [materials, setMaterials] = useState([])
   const [filteredMaterials, setFilteredMaterials] = useState([])
   const [idMaterial, setIdMaterial] = useState('')
   const [material, setMaterial] = useState('')

   const [quantity, setQuantity] = useState(0)

   useEffect(_ => {
      loadRule()
      loadTemplates()
      loadSubRules()        
   }, [])

   useEffect(_ => {
      loadTemplateMaterials()
   }, [template])

   useEffect(_ => {
      loadMaterial()
   }, [idMaterial])

   const loadRule = async () => {
      const ruleResult = await getRule({
         idOperator,
         component,
         idRule,
      })
   
      if (ruleResult) {
         setRule(ruleResult)
      }
   }

   const loadTemplates = async () => {
      const templatesResult = await getTemplates({ 
         idOperator,
      })

      if (templatesResult) {
         for (const k in templatesResult) {
            if (templatesResult[k].isDefault) {
               setTemplate(templatesResult[k].date)
               break
            }
         }

         setTemplates(templatesResult)
      }
   }

   const loadSubRules = async () => {
      const subRulesResult = await getSubRules({
         idOperator,
         component,
         idRule
      })

      if (subRulesResult) {
            setSubRules(subRulesResult)
      }
   }

   const loadTemplateMaterials = async () => {
      if (template != '') {
         const materialsResult = await getTemplate({ 
            idOperator,
            templateDate: template
         })

         setMaterials(materialsResult)
         setFilteredMaterials(materialsResult)
      }
   }

   const loadMaterial = async () => {
      if (template != '') {
         async function get() {
            const materialResult = await getMaterial({
               idOperator,
               templateDate: template,
               idMaterial,
            })
   
            setMaterial(materialResult)
         }
   
         if (idMaterial) {
            get() // Removed the arguments from this line
         }
      }
   }

   const handleSubmit = async (event) => {
      event.preventDefault()
      
      var subRule = {}
      
      if (template != '' && quantity > 0 && idMaterial != '') {
         subRule['idMaterial'] = idMaterial
         subRule['quantity'] = quantity 
         subRule['applyMeters'] = selectedOption == 'no' ? 0 : applyMeters
         
         const obj = await postSubRule({
               idOperator,
               component,
               idRule,
               subRule,
         })

         if (obj) {
               subRule['_id'] = obj
               setSubRules([...subRules, subRule])
         } 
   
      } else {
         toast.error("Formulário incompleto!")
      }
   }

   const handleDelete = async (idSubRule) => {
      await deleteSubRule({
         idOperator,
         component,
         idRule,
         idSubRule,
      })

      setSubRules(subRules.filter(sr => sr._id != idSubRule))
   }

   const handleSearchMaterials = (e) => {
      const searchValue = e.target.value.toLowerCase()

      setFilteredMaterials(materials.filter(m => m.name.toLowerCase().includes(searchValue)))
   }

   return (
      <>
      <div className='contentTitle'>
         <h2>Sub Regras</h2>
      </div>
      <div className='content'>
         <div className='grid-three'>
            <div className='grid-column'>
               <h3>Criação</h3>
               <div className='form-container'>
                  <ul className='form'>
                     {
                     templates && (
                        <li className='card' key='template'>
                           <label htmlFor="templates"><strong>Template: </strong></label>
                           <select 
                              className={`selectBox ${template ? '' : 'red'}`} 
                              value={template}
                              name="templates"
                              id="templates"
                              onChange={(e) => {setTemplate(e.target.value)}}
                           >
                              <option value="" key="">-- Selecione um template --</option>
                              {
                              templates.map(t => (
                                 <option value={t.date} key={t.date}>{t.date}</option>
                              ))
                              }
                           </select>
                        </li>
                     )
                     }
                     
                           
                     <li className='card' key='material'>
                        <label htmlFor="material"><strong>Material:</strong></label>
                        <div className='container-ver'>
                           <div className='container-ver-item'>
                              <input className="inputBox" type="text" placeholder="Procure um material..." onChange={handleSearchMaterials} />
                           </div>
                           <div className='container-ver-item'>
                              <select 
                                 className="selectBox"
                                 name="material"
                                 id="material"
                                 onChange={(e) => {
                                    setIdMaterial(e.target.value)
                                    setQuantity(1)
                                 }}
                              >
                                 <option value="">--Selecione o material--</option>
                                 {
                                    filteredMaterials && filteredMaterials.map(m => (
                                       <option value={m.key} key={m.key}>{m.name}</option>
                                    ))
                                 }
                              </select>
                           </div>
                        </div>
                     </li>
                     
                     {
                        material && (
                           <div className='card grey'>
                              <ul>
                                 <li>
                                    <span><strong>Id:</strong></span> 
                                    <span>{material.id}</span>
                                 </li>
                                 <li>
                                    <span><strong>Nome:</strong></span>
                                    <span>{material.name}</span>
                                 </li>
                              </ul>
                           </div>
                        )
                     }
                     <li className='card' key='quantity'>
                        <label htmlFor="quantity"><strong>Quantidade:</strong></label>
                        <input 
                           className="inputBox"
                           name="quantity"
                           id="quantity"
                           type="number"
                           value={quantity}
                           onChange={(e) => {setQuantity(e.target.value)}}
                        />
                     </li>
                     <li className='card' key='application'>
                        <label>Aplicação ao metro?</label>
                        <div className='container-ver'>
                           <div className='container-ver-item'>
                              <select className="selectBox" value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                                    <option value="yes">Sim</option>
                                    <option value="no">Não</option>
                              </select>
                           </div>
                           {
                              selectedOption == 'yes' && (
                                    <div className='container-ver-item'>
                                       <input className="inputBox" type="Number" value={applyMeters} onChange={(e) => setApplyMeters(e.target.value)} />
                                    </div>
                              )
                           }
                        </div>
                     </li>
                  </ul>
               </div>
               <ul className='button-list-ver'>
                  <li className='button-item'><button className="button" onClick={handleSubmit}>Gravar</button></li>
               </ul>
            </div>
            <div className='grid-column'>
               <h3>Listagem</h3>
               {
               (subRules && subRules.length > 0) && (
                  <div className='box'>
                     <ul className='card-list'>
                     {
                        subRules.map(sr => {
                           const m = materials.find(m => m.key === sr.idMaterial)

                           return (
                              <li className={`card ${!m && 'grey'}`} key={sr.idMaterial}>
                                 <div className='card-content-ver'>
                                    <ul className='card-info'>
                                       {!m && <li>Material não existe no template!</li>}
                                       {m && <li><strong>ID:</strong> {m.id}</li>}
                                       {m && <li><strong>Material:</strong> {m.name}</li>}
                                       <li><strong>Quantity:</strong> {sr.quantity}</li>
                                       {sr.applyMeters != 0 && (<li><strong>Aplicar ao metro:</strong> {sr.applyMeters}</li>)}
                                    </ul>
                                    <ul className='button-list-hor'>
                                       <li className='button-item'><button className="button delete" onClick={() => handleDelete(sr._id)}>Apagar</button></li>
                                    </ul>
                                 </div>
                              </li>
                           )
                        })
                     }
                     </ul>
                  </div>
               )
               }
            </div>
            <div className='grid-column'>
               <h3>{t(`tabs.${component}`)}</h3>
               {
                  rule && (
                        <RuleSpec rule={rule} component={component}/>
                  )
               }
            </div>
         </div>
      </div>
      </>
   )
}

export default SubRule