import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Confirm } from 'semantic-ui-react'

import { deleteProject, getProjects } from '../../../controllers/project'
import { addProject } from '../../../controllers/project'
import { getOperatorsByModel } from '../../../controllers/operator'

import { useAuthContext } from '../../context/AuthContext'

import ButtonList from '../../components/ButtonList'
import ButtonTabs from '../../components/ButtonTabs'

function GestProjects() {
   const { privilege, models } = useAuthContext()
   const [model, setModel] = useState(
      localStorage.getItem('selectedModel') || undefined
   )
   const [operators, setOperators] = useState([])

   const [selectedOperator, setSelectedOperator] = useState(
      localStorage.getItem('selectedOperator') || undefined
   )
   const [idOperator, setIdOperator] = useState(
      localStorage.getItem('idOperator') || undefined
   )
   const [projects, setProjects] = useState(undefined)
   const [filteredProjects, setFilteredProjects] = useState([])

   const [projectName, setProjectName] = useState('')
   const [showAddProjectForm, setShowAddProjectForm] = useState(false)

   //Confirmation Modal
   const [openMap, setOpenMap] = useState({})

   useEffect(
      (_) => {
         loadOperators()
         setProjects(undefined)
      },
      [model]
   )

   useEffect(
      (_) => {
         loadProjects()
      },
      [idOperator]
   )

   useEffect(
      (_) => {
         setFilteredProjects(projects)
      },
      [projects]
   )

   const loadOperators = async () => {
      if (model) {
         const operatorsResult = await getOperatorsByModel({
            model,
         })

         if (operatorsResult) {
            setOperators(operatorsResult)
         }
      }
   }

   const loadProjects = async () => {
      if (idOperator) {
         var projectsResult = await getProjects({
            idOperator,
         })

         if (projectsResult) {
            setProjects(projectsResult)
         }
      }
   }

   const handleSelectOperator = (filter) => {
      setSelectedOperator(filter)
      const operator = operators.filter((o) => o.name == filter)[0]
      setIdOperator(operator._id)
      setShowAddProjectForm(false)
      setProjectName('')
      localStorage.setItem('selectedOperator', filter)
      localStorage.setItem('idOperator', operator._id)
   }

   const handleSelectModel = (filter) => {
      setModel(filter)
      setIdOperator(undefined)
      setSelectedOperator(undefined)
      localStorage.setItem('selectedModel', filter)
      localStorage.removeItem('selectedOperator')
      localStorage.removeItem('idOperator')
      setProjects(undefined)
   }

   const handleSubmeter = async () => {
      const specialCharsRegex = /[!#$%&'*+,:;=?@[\]^`{|}~]+/

      if (projectName != '' && !specialCharsRegex.test(projectName)) {
         const projectInserted = await addProject({
            idOperator,
            projectName,
         })

         if (projectInserted) {
            setShowAddProjectForm(false)
            setProjects([...projects, projectInserted])
         }
      } else {
         toast.error('Formulario inválido!')
      }
   }

   const handleDelete = async (idOperator, idProject) => {
      await deleteProject({
         idOperator,
         idProject,
      })

      setProjects(projects.filter((p) => p.id != idProject))
   }

   const handleOpen = (idProject) => {
      setOpenMap({ ...openMap, [idProject]: true })
   }

   const handleClose = (idProject) => {
      setOpenMap({ ...openMap, [idProject]: false })
   }

   const handleFilterProjects = (filter) => {
      const lowerCaseFilter = filter.toLowerCase()
      const filteredProjects = projects.filter((p) =>
         p.name.toLowerCase().includes(lowerCaseFilter)
      )
      setFilteredProjects(filteredProjects)
   }

   return (
      <>
         <div className="contentTitle">
            <h2>Gestão de Projetos</h2>
         </div>
         <div className="grid-two">
            <div className="grid-column">
               <div className="column-title">
                  <h2>Operadoras</h2>
               </div>
               <ButtonTabs
                  filters={models}
                  handleFilterClick={handleSelectModel}
                  selectedFilter={model}
               />
               <hr></hr>
               {model && (
                  <>
                     {operators.length > 0 ? (
                        <ButtonList
                           filters={operators.map((p) => p.name)}
                           handleFilterClick={handleSelectOperator}
                           selectedFilter={selectedOperator}
                        />
                     ) : (
                        <h3>Ainda sem operadoras...</h3>
                     )}
                  </>
               )}
            </div>
            <div className="grid-column">
               <div className="column-title">
                  <h2>Projetos</h2>
               </div>
               {idOperator &&
                  (showAddProjectForm ? (
                     <>
                        <div className="form-container">
                           <ul className="form">
                              <li>
                                 <label>Nome do projeto:</label>
                                 <input
                                    className="inputBox"
                                    type="text"
                                    onChange={(e) =>
                                       setProjectName(e.target.value)
                                    }
                                 />
                              </li>
                           </ul>
                        </div>
                        <ul className="button-list-hor">
                           <li className="button-item">
                              <button
                                 className="button"
                                 onClick={() => handleSubmeter()}
                              >
                                 Gravar
                              </button>
                           </li>
                           <li className="button-item">
                              <button
                                 className="button"
                                 onClick={(_) => setShowAddProjectForm(false)}
                              >
                                 Cancelar
                              </button>
                           </li>
                        </ul>
                     </>
                  ) : (
                     privilege != 'partner' && (
                        <ul className="button-list-ver">
                           <li className="button-item">
                              <button
                                 className="button"
                                 onClick={(_) => setShowAddProjectForm(true)}
                              >
                                 Adicionar Projeto
                              </button>
                           </li>
                        </ul>
                     )
                  ))}
               <hr></hr>
               <div className="container">
                  {idOperator &&
                     (!projects == undefined ? (
                        <h3>Selecione uma operadora...</h3>
                     ) : (
                        <>
                           {projects?.length > 0 ? (
                              <>
                                 <input
                                    className="inputBox"
                                    type="text"
                                    onChange={(e) =>
                                       handleFilterProjects(e.target.value)
                                    }
                                    placeholder="Procure o projeto..."
                                 ></input>
                                 {filteredProjects?.length > 0 ? (
                                    filteredProjects.map((p) => (
                                       <ul className="card-list" key={p}>
                                          <li className="card">
                                             <ul className="button-list-hor">
                                                <li className="button-item">
                                                   <Link
                                                      className="button"
                                                      to={`/project/${idOperator}/${p.id}`}
                                                   >
                                                      {p.name}
                                                   </Link>
                                                </li>
                                                {privilege !== 'partner' && (
                                                   <>
                                                      <li className="button-item">
                                                         <Button
                                                            className="button delete"
                                                            onClick={() =>
                                                               handleOpen(p.id)
                                                            }
                                                         >
                                                            Apagar
                                                         </Button>
                                                      </li>
                                                      <Confirm
                                                         open={
                                                            openMap[p.id] ||
                                                            false
                                                         }
                                                         onCancel={() =>
                                                            handleClose(p.id)
                                                         }
                                                         onConfirm={() => {
                                                            handleDelete(
                                                               idOperator,
                                                               p.id
                                                            )
                                                            handleClose(p.id)
                                                         }}
                                                         content={
                                                            <h3>
                                                               Pretende apagar o
                                                               projeto?
                                                            </h3>
                                                         }
                                                         confirmButton={{
                                                            content:
                                                               'Confirmar',
                                                            className:
                                                               'button-item delete',
                                                         }}
                                                         cancelButton={{
                                                            content: 'Cancelar',
                                                            className:
                                                               'button-item',
                                                         }}
                                                         className="button-list-hor confirm-modal"
                                                      />
                                                   </>
                                                )}
                                             </ul>
                                          </li>
                                       </ul>
                                    ))
                                 ) : (
                                    <div className="container">
                                       <h3>Sem projetos...</h3>
                                    </div>
                                 )}
                              </>
                           ) : (
                              <h3>Ainda não existem projetos...</h3>
                           )}
                        </>
                     ))}
               </div>
            </div>
         </div>
      </>
   )
}

export default GestProjects
