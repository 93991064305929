import React, { createContext, useContext, useState } from 'react'

const BomContext = createContext()

export const useBomContext = () => useContext(BomContext)

export const BomProvider = ({ children }) => {
   const [preBom, setPreBom] = useState(false)
   const [bom, setBom] = useState(false)

   const updatePreBom = (status) => {
      setPreBom(status)
   }

   const updateBom = (status) => {
      setBom(status)
   }

   return (
      <BomContext.Provider value={{ preBom, bom, updatePreBom, updateBom}}>
         {children}
      </BomContext.Provider>
   )
}
