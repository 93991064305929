import React, {useEffect, useState } from 'react'
import { Link,useParams } from 'react-router-dom'

import { getTemplate } from '../../../controllers/template'

function Template() {
    const { idOperator,templateDate } = useParams()

    const [materials, setMaterials] = useState([])
    const [searchTerm, setSearchTerm] = useState("")

    
    useEffect(_ => {
        loadTemplate()
    }, [])

    const loadTemplate = async () => {
        const materialsResult = await getTemplate({ 
            idOperator,
            templateDate
        })

        setMaterials(materialsResult)
    }

    const handleSearch = (event) => {
        setSearchTerm(event.target.value)
    }

    const filteredMaterials = materials ? materials.filter(m => String(m.name).toLowerCase().includes(searchTerm.toLowerCase())) : []

    return (
        <>
        <div className='contentTitle'>
            <h2>Materials</h2>
        </div>
        <div className='content'>
            {
                filteredMaterials && (
                    <>
                    <div>
                        <input className='inputBox' type="text" placeholder="Procure um material" onChange={handleSearch} />
                    </div>
                    <div>
                        <ul>
                            {
                                filteredMaterials.map(m => (
                                    <li value={m[m._id]} key={m._id}>
                                        <Link className='button' to={`/template/${idOperator}/${templateDate}/${m.key}`}>{m.name}</Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    </>
                )
            }
        </div>
        </>
    )
}

export default Template