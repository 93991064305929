import React, {useState, useEffect} from 'react'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

import { getTemplates, deleteTemplate, setDefault } from '../../../../controllers/template'

function Templates({idOperator}) {
   const [templates, setTemplates] = useState([])

   useEffect(_ => {
      loadTemplates()
   }, [])

   const loadTemplates = async () => {
      const result = await getTemplates({ 
         idOperator,
      })
      
      if (result) {
         setTemplates(result)
      }
   }

   const handleDelete = async (templateDate) => {
      await deleteTemplate({
            idOperator,
            templateDate,
      })
      
      setTemplates(templates.filter(t => t.date !== templateDate))
   }

   const handleDefault = async (templateDate) => {
      const res = await setDefault({
         idOperator,
         templateDate,
      })

      if (res) {
         setTemplates(prevState => {
            const auxState = prevState.map(t => ({ ...t, isDefault: false }))
            const auxStateUpdated = auxState.map(t => t.date == templateDate ? {...t, isDefault: true} : t)

            return auxStateUpdated
         })
      }
   }

   return (
      <div className="grid-two">
         <div className="grid-column">
            <div className='column-title'>
               <h2>Gestão de Templates</h2>
            </div>
            <ul className='button-list-ver'>
               <li className='button-item'>
                  <Link className='button' to={`/template/${idOperator}/addTemplate`}>Adicionar Template</Link>
               </li>
            </ul>

         </div>
         <div className="grid-column">
            <div className='column-title'>
               <h2>Templates</h2>
            </div>
            <div>
            {
               templates && templates.length > 0 ? (
                  <ul className='card-list'>
                     {
                        templates.map(t => (
                           <li className="card" key={t.date}>
                              <ul className='button-list-hor'>
                                    <li className='button-item'>
                                       <Link className="button" to={`/template/${idOperator}/${t.date}`}>{t.date}</Link>
                                    </li>
                                    <li className='button-item'>
                                       <button className={`button ${t.isDefault ? 'selected' : ''}`} onClick={() => handleDefault(t.date)}>{t.isDefault ? 'Marcado' : 'Marcar default'}</button>
                                    </li>
                                    <li className='button-item'>
                                       <button className="button delete" onClick={() => handleDelete(t.date)}>Apagar</button>
                                    </li>
                              </ul>
                           </li>
                        ))
                     }
                  </ul>
               ) : (
                  <h3>Não existem templates...</h3>
               )
            }
            </div>
         </div>
      </div>
   )
}

export default Templates