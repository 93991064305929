import React, { useContext, useEffect, useState } from 'react'

import DynamicList from '../../../components/DynamicList'
import GeneralContext from  '../../../context/GeneralContext'

function Plc() {
  const [loading,setLoading] = useState(true)
  const { elements: rule, setElements: setRule} = useContext(GeneralContext)

  useEffect(() => {
    if (!rule) {
        setRule({
          installations: [],
          types: []
        })
    }
    setLoading(false)
  }, [])


  if (!loading) {
    const contextVariables = Object.keys(rule).reduce((acc, key) => {
        acc[key] = {
          elements: rule[key],
          setElements: (value) => setRule((prevState) => ({ ...prevState, [key]: value }))
        }
        return acc
    }, {})
  
    return (
        <>
        {
          Object.entries(contextVariables).map(([name,context]) =>(
              <GeneralContext.Provider name={name} value={context}>
                <DynamicList context="rules" name={name}></DynamicList>
              </GeneralContext.Provider>
          ))
        }
    </>
    )
  }
}

export default Plc