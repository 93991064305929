const NOS_PRIVS = [process.env['REACT_APP_ADMIN_NOS'],process.env['REACT_APP_REGULAR_NOS']]
const VOD_PRIVS = [process.env['REACT_APP_ADMIN_VOD'],process.env['REACT_APP_REGULAR_VOD']]

const env = (key) => {
   return process.env[`REACT_APP_${key}`]
}

module.exports = {
   NOS_PRIVS,
   VOD_PRIVS,
   env
}