import axios from 'axios';
import Cookies from 'js-cookie';

import {statusCheck} from './middlewares/responseChecker';
import { env } from '../config/config';

const API = env('SERVER_API')

export const getTemplates = async (args) => {
    try {
        const {idOperator} = args
        const res = await axios.get(`${API}/template/getTemplates`, {
            params: {
                idOperator,
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: Get templates")
    }
}


export const getMaterial = async (args) => {
    try {
        const {idOperator, templateDate, idMaterial} = args
        const res = await axios.get(`${API}/template/getMaterial`, {
            params: {
                idOperator,
                templateDate,
                idMaterial
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: Get Material")
    }
}

export const getTemplate = async (args) => {
    try {
        const {idOperator, templateDate} = args
        const res = await axios.get(`${API}/template/getTemplate`, {
            params: {
                idOperator,
                templateDate
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }
    } catch (error) {
        console.log("ERRO: Get Materiais")
    }
}

export const deleteTemplate = async (args) => {
    try {
        const {idOperator, templateDate} = args
        const res = await axios.delete(`${API}/template/deleteTemplate`, {
            params: {
                idOperator,
                templateDate
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
        
        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: Delete Rule")
    }
}

export const parseTemplate = async (args) => {
    try {
        const {idOperator,fileName,data} = args

        console.log('parseTEMPLATE')
        const res = await axios.post(`${API}/template/parseTemplate`, {
            idOperator,
            fileName,
            data
        }, JSON.parse(Cookies.get('authHeader')));
        
        if (statusCheck(res)) {
            return res.data
        }
    } catch (error) {
        console.log("ERRO: ParseTemplate")
    }
}


export const addTemplate = async (args) => {
    try {

        const {idOperator,templateDate,templateMap,templateName,templateContent} = args

        const res = await axios.post(`${API}/template/addTemplate`, {
            idOperator,
            templateDate,
            templateMap,
            templateName,
            templateContent
        }, JSON.parse(Cookies.get('authHeader')));
        
        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: ParseTemplate")
    }
}


export const setDefault = async (args) => {
    try {
        const {idOperator,templateDate} = args

        const res = await axios.put(`${API}/template/setDefault`, {
            idOperator,
            templateDate,
        }, JSON.parse(Cookies.get('authHeader')));
        
        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: setDefaultTemplate")
    }
}

