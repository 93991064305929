import { toast } from 'react-toastify';


export const statusCheck = (res) => {

    if (res.status === 200) {
        return true        

    } else if (res.status === 210) { //it means problems with authentication
        toast.error(res.data.msg)    
        return false

    } else {

        if (res.data.msg) {
            toast.error(res.data.msg)

        } else {
            toast.error("Ocorreu um erro no servidor...")
        }

        return false
    }

}
