import React, { useEffect, useState, useContext } from 'react'
import { toast } from 'react-toastify'

import { addUserAccess, getProjectUsers, giveAccess, removeAccess, removeUserAccess} from '../../../../controllers/project'
import { getUsers, getUsersByPrivilege } from '../../../../controllers/user'
import { useAuthContext } from '../../../context/AuthContext'
import { useParams } from 'react-router-dom'


const Access = () => {
   const { user } = useAuthContext()
   const { idOperator, idProject } = useParams()

   const [showAccessForm, setShowAccessForm] = useState(false)
   const [projectUsers, setProjectUsers] = useState([])

   const [users, setUsers] = useState([])
   const [selUser, setSelUser] = useState(undefined)


   useEffect(() => {
      const fetch = async () => {
         const projectUsersResult = await getProjectUsers({
            idOperator,
            idProject,
         })

         if (projectUsersResult) {
            setProjectUsers(projectUsersResult)
         }

         const usersResult = await getUsersByPrivilege({
            privilege: "partner"
         })

         if (usersResult) {
            setUsers(usersResult)
         }
      }
      fetch()
   }, [idOperator, idProject])


   const handleGiveAccess = async () => {
      if (selUser) {
         const res = await addUserAccess({
            idOperator,
            idProject,
            user: selUser
         })
         
         if (res) {
            setShowAccessForm(false)
            setSelUser(undefined)
            setProjectUsers([...projectUsers, selUser])
         }

      } else {
         toast.error("Formulário incompleto")
      }
   }

   const handleRemoveAccess = async (seluser) => {
      const res = await removeUserAccess({
         idOperator,
         idProject,
         user: seluser
      })

      if (res) {
         setProjectUsers(projectUsers.filter(u => u != seluser))
      }
   }

   return (
      <div className='grid-two'>
         <div className='grid-column'>
            <div className='column-title'>
               <h2>Acessos</h2>
            </div>
            {
               showAccessForm && (
                  <>
                  <div className='form-container'>
                     <ul className='form'>
                        <li key="user">
                           <label>Parceiro:</label>
                           <select className='selectBox' name="user" id="user" onChange={(e) => setSelUser(e.target.value)}>
                              <option value="">-- Selecione um opção --</option>
                              {
                                 users.map(u => (
                                    u.name != user && <option value={u.id} key={u.id}>{u.name}</option>
                                 ))
                              }
                           </select>
                        </li>
                     </ul>
                  </div>
                  <ul className='button-list-hor'>
                     <li className='button-item'>
                        <button className="button" onClick={handleGiveAccess}>Gravar</button>
                     </li>
                     <li className='button-item'>
                        <button className="button" onClick={_ => setShowAccessForm(false)}>Cancelar</button>
                     </li>
                  </ul>
                  </>
               )
            }
            {
               !showAccessForm && (
                  <ul className='button-list-hor'>
                     <li className='button-item'>
                        <button className="button" onClick={_ => setShowAccessForm(true)}>Adicionar Acesso</button>
                     </li>
                  </ul>
               )
            }
         </div>
        
         {
            <div className='grid-column'>
               <div className='column-title'>
                  <h2>Autorizados</h2>
               </div>
               <div className='container'>
               {
                  (projectUsers && projectUsers.length > 0 && users && users.length > 0) ? (
                     <ul className='card-list'>
                        {
                           projectUsers.map(u => {
                              const userObject = users.find(user => user.id === u)
                           
                              return (
                                 <li key={u} className='card'>
                                    <div className='card-content-hor'>
                                       <ul>
                                          <li>Parceiro: <strong>{userObject.name}</strong></li>
                                       </ul>
                                       <ul className='button-list-hor'>
                                          <li className='button-item'>
                                             <button className='button delete' onClick={() => handleRemoveAccess(u)}>Remover</button>
                                          </li>
                                       </ul>
                                    </div>
                                 </li>
                              )
                           })
                        }
                     </ul>
                  ) : (
                     <h3>Nenhum parceiro tem acesso.</h3>
                  )
               }
               </div>
            </div>
         }
      </div>
   )
}

export default Access