import React, { useState, useEffect, useContext } from 'react'
import DynamicList from '../../../../components/DynamicList'
import GeneralContext from  '../../../../context/GeneralContext'

function Habitation() {
    const [loading, setLoading] = useState(true)
    const { elements: habitationAttrs, setElements: setHabitationAttrs} = useContext(GeneralContext)

    useEffect(() => {
        setHabitationAttrs({
            blocks: [],
            layers: [],
            ids: [],
            uas: [],
            plcs: [],
            streets: [],
            attendances: [],
            installations: [],
            latitudes: [],
            longitudes: []
        })
        setLoading(false)
    },[])

    if (!loading) {
        
        const contextVariables = Object.keys(habitationAttrs).reduce((acc, key) => {
            acc[key] = {
               elements: habitationAttrs[key],
               setElements: (value) => setHabitationAttrs((prevState) => ({ ...prevState, [key]: value }))
            }
            return acc
        }, {})

        return (
            <>
                {
                    Object.entries(contextVariables).map(([name, context]) => (
                        <GeneralContext.Provider key={name} value={context}>
                            <DynamicList context="components" name={name}></DynamicList>
                        </GeneralContext.Provider>
                    ))
                }
                
            </>
        )
    }
}

export default Habitation
