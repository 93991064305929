import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { FaToolbox, FaFolder, FaUser, FaBug } from 'react-icons/fa'

import { useAuthContext } from '../context/AuthContext'

import { env } from '../../config/config'

function Main() {
   const { privileges } = useAuthContext()
   return (
      <>
         <div className="contentTitle">
            <h2>FTTx Next</h2>
         </div>
         <hr></hr>
         <div className="main-content">
            <div className="main-content-column">
               {privileges.length > 0 ? (
                  <>
                     {!privileges.includes('PARTNER') ? (
                        <>
                           <div className="main-content-row">
                              <div className="main-container">
                                 <Link
                                    className="mainpage-item"
                                    to="/gestProjects"
                                 >
                                    <FaFolder className="icon" />
                                    <p>Projetos</p>
                                 </Link>
                              </div>
                              <div className="main-container">
                                 <Link
                                    className="mainpage-item"
                                    to="/gestOperators"
                                 >
                                    <FaToolbox className="icon" />
                                    <p>Operadoras</p>
                                 </Link>
                              </div>
                              <div className="main-container">
                                 <Link className="mainpage-item" to="/logs">
                                    <FaBug className="icon" />
                                    <p>Histórico</p>
                                 </Link>
                              </div>
                              {privileges.includes(env('ADMIN')) && (
                                 <div className="main-container">
                                    <Link className="mainpage-item" to="/users">
                                       <FaUser className="icon" />
                                       <p>Utilizadores</p>
                                    </Link>
                                 </div>
                              )}
                           </div>
                        </>
                     ) : (
                        <li className="button-item">
                           <Link className="button" to="/gestProjects">
                              <FaFolder className="icon" />
                              <p>Projetos</p>
                           </Link>
                        </li>
                     )}
                  </>
               ) : (
                  <h2>Sem acesso: entre em contacto com o IT!</h2>
               )}
            </div>
         </div>
      </>
   )
}

export default Main
