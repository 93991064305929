// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
}

.contentTitle {
  margin: 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui/Body.scss"],"names":[],"mappings":"AAAA;EACG,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,cAAA;EACA,cAAA;AACH;;AAEA;EACG,cAAA;AACH","sourcesContent":[".mainContent {\n   display: flex;\n   flex-direction: column;\n   justify-content: center;\n   text-align: center;\n   max-width: 70%;\n   margin: 0 auto;\n}\n\n.contentTitle {\n   margin: 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
