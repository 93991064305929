// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul[role=list], ol[role=list] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture, svg, video, canvas {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *, *::before, *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition: none;
  }
}
body, html {
  height: 100%;
  scroll-behavior: smooth;
}`, "",{"version":3,"sources":["webpack://./src/styles/reset.css"],"names":[],"mappings":"AAAA;EACC,sBAAA;AACD;;AAEA;EACC,SAAA;EACA,UAAA;AACD;;AAEA;EACC,gBAAA;AACD;;AAEA;EACC,uBAAA;AACD;;AAEA;EACC,8BAAA;AACD;;AAEA;EACC,eAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,4BAAA;EACA,sBAAA;AACD;;AAEA;EACC,aAAA;AACD;;AAEA;EACC;IACE,qBAAA;EACD;EACD;IACE,qCAAA;IACA,uCAAA;IACA,sCAAA;IACA,gCAAA;IACA,gBAAA;EACD;AACF;AAEA;EACC,YAAA;EACA,uBAAA;AAAD","sourcesContent":["*, *::before, *::after{\n\tbox-sizing: border-box; \n}\n\n*{\n\tmargin: 0; \n\tpadding: 0; \n}\n\nul[role='list'], ol[role='list']{\n\tlist-style: none; \n}\n\nhtml:focus-within{\n\tscroll-behavior: smooth; \n}\n\na:not([class]){\n\ttext-decoration-skip-ink: auto; \n}\n\nimg, picture, svg, video, canvas{\n\tmax-width: 100%;\n\theight: auto; \n\tvertical-align: middle; \n\tfont-style: italic; \n\tbackground-repeat: no-repeat; \n\tbackground-size: cover;\n}\n\ninput, button, textarea, select{\n\tfont: inherit; \n}\n\n@media (prefers-reduced-motion: reduce){\n\thtml:focus-within {\n\t\t scroll-behavior: auto;\n\t}\n\t*, *::before, *::after {\n\t\t animation-duration: 0.01ms !important;\n\t\t animation-iteration-count: 1 !important;\n\t\t transition-duration: 0.01ms !important;\n\t\t scroll-behavior: auto !important;\n\t\t transition: none;\n\t}\n}\n\nbody, html{\n\theight: 100%; \n\tscroll-behavior: smooth; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
