import axios from 'axios';
import Cookies from 'js-cookie';

import {statusCheck} from './middlewares/responseChecker';
import { env } from '../config/config';

const API = env('SERVER_API')

export const getRules = async (args) => {
    try {
        const {idOperator} = args

        const res = await axios.get(`${API}/rule/getRules`, {
            params: {
                idOperator,
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }
    } catch (error) {
        console.log("ERRO: Get rules")
    }
}

export const getComponentRules = async (args) => {
    try {
        const {idOperator,component} = args

        const res = await axios.get(`${API}/rule/getComponentRules`, {
            params: {
                idOperator,
                component
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }
    } catch (error) {
        console.log("ERRO: Get rules")
    }
}

export const getRule = async (args) => {
    try {

        const {idOperator,component,idRule} = args

        var rule

        const res = await axios.get(`${API}/rule/getRule`, {
            params: {
                idOperator,
                component,
                idRule
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
        
        if (statusCheck(res)) {
            rule = res.data
        }

        if (rule && rule.rules && rule.rules[component] && rule.rules[component][0] !== undefined) {
            return rule.rules[component][0]
        } else {
            return null;
        }
    } catch (error) {
        console.log("ERRO: Get Rule")
    }
}

export const getRuleOptions = async (args) => {
    try {

        const {idOperator,component} = args

        const res = await axios.get(`${API}/rule/getRuleOptions`, {
            params: {
                idOperator,
                component
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
        
        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: getRuleOptions")
    }
}



export const getMaterialsApplied = async (args) => {
    try {
        const {idOperator, templateDate} = args

        const res = await axios.get(`${API}/rule/materialsApplied`, {
            params: {
                idOperator,
                templateDate
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
       
        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: Get rules by material")
    }
}

export const getRulesByMaterial = async (args) => {
    try {
        const {idOperator, idMaterial} = args

        const res = await axios.get(`${API}/rule/rulesByMaterial`, {
            params: {
                idOperator,
                idMaterial
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: Get rules by material")
    }
}


export const getSubRules = async (args) => {
    try {
        const {idOperator, component, idRule } = args

        const res = await axios.get(`${API}/rule/subRules`, {
            params: {
                idOperator,
                component,
                idRule
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            var subRules = res.data.rules[component][0].subRules
            return subRules
        }
      
    } catch (error) {
        console.log("ERRO: Get Sub rules")
    }
}

export const postRule = async (args) => {
    try {
        const {idOperator, component, rule} = args

        const res = await axios.post(`${API}/rule/addRule`, {
            idOperator,
            component,
            'newRule': rule
        }, JSON.parse(Cookies.get('authHeader')));
        
        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: Post Rule")
    }
}

export const postSubRule = async (args) => {
    try {
        const {idOperator, component, idRule, subRule} = args

        const res = await axios.post(`${API}/rule/addSubRule`, {
            idOperator,
            component,
            idRule,
            'newRule': subRule
        }, JSON.parse(Cookies.get('authHeader')));
        
        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: Post Sub Rule")
    }
}

export const putRule = async (args) => {
    try {
        const {idOperator, component, idRule, rule} = args

        const res = await axios.put(`${API}/rule/addRule`, {
            idOperator,
            component,
            idRule,
            'updatedRule': rule
        }, JSON.parse(Cookies.get('authHeader')));
        
        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: Put Rule")
    }
}

export const deleteRule = async (args) => {
    try {
        const {idOperator, component, idRule} = args

        const res = await axios.delete(`${API}/rule/deleteRule`, {
            params: {
                idOperator,
                component,
                idRule
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
        
        if (statusCheck(res)) {
            return res
        }

    } catch (error) {
        console.log("ERRO: Delete Rule")
    }
}

export const deleteSubRule = async (args) => {
    try {
        const {idOperator,component,idRule,idSubRule} = args

        const res = await axios.delete(`${API}/rule/deleteSubRule`, {
            params: {
                idOperator,
                component,
                idRule,
                idSubRule
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
        
        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: Delete Sub Rule")
    }
}
