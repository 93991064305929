import React from 'react'
import { Link } from 'react-router-dom'

import RuleSpec from './RuleSpec'

function RuleList({ idOperator, component, rules }) {
   if (!Array.isArray(rules)) {
      return <p>No rules available</p>;
   }

   return (
      <>
         <ul className='card-list'>
            {
            rules.map(({ _id, modifiedAt, ...rest}) => (
               <li className='card' value={_id} key={_id}>
                  <div>
                     <h5><small>Última modificação: {new Date(modifiedAt).toLocaleString()}</small></h5>
                  </div>
                  <div className='card-content-hor'>
                     <RuleSpec component={component} rule={rest}/>
                     <ul className='button-list-hor'>
                        <li className='button-item'>
                           <Link className="button" to={`/rule/${idOperator}/${component}/${_id}`}>Editar Regra</Link>
                        </li>
                        <li className='button-item'>
                           <Link className="button" to={`/rule/subRules/${idOperator}/${component}/${_id}`}>Sub Regras</Link>
                        </li>
                     </ul>
                  </div>
               </li>
            ))
            }
         </ul>
      </>
   );
}

export default RuleList