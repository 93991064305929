import React, { useState, useEffect, useContext } from 'react'

import { getUsers, deleteUser} from '../../../controllers/user';


function Users() {
   const [users, setUsers] = useState([]);

   useEffect(_ => {
      loadUsers()
   }, [])

   const loadUsers = async () => {
      const usersResult = await getUsers();

      if (usersResult) {
         setUsers(usersResult)
      }
   }


   const handleDelete = async (idUser) => {
      var res = await deleteUser({idUser});

      if (res) {
         setUsers(users.filter(user => user.id !== idUser));
      }
   }

   return (
      <>
         <div className='contentTitle'>
            <h2>Utilizadores</h2>
         </div>
         <div className="content">
            <ul className='card-list'>
               {
                  users && users.map(u => (
                     <li className="card" key={u.id}>
                        <div className='card-content-hor'>
                           <ul>
                              <li><strong>Utilizador:</strong> {u.name}</li>
                              <li><strong>Email:</strong> {u.email}</li>
                           </ul>
                           <div>
                              <ul className='button-list-hor'>
                                 <li className='button-item'>
                                    <button className='button delete' onClick={_ => handleDelete(u.id)}>Apagar</button>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </li>
                  ))
               }
            </ul>
         </div>
      </>
   )
}

export default Users