// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal .actions {
  display: flex;
  justify-content: center;
  width: 400px;
}

.options .button {
  width: 100%;
}

.img-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.img-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.img-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ccc;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/functional/Modals.scss"],"names":[],"mappings":"AAEA;EACG,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AADH;;AAIA;EACG,aAAA;EACA,uBAAA;EACA,YAAA;AADH;;AAIA;EACG,WAAA;AADH;;AAIA;EACG,aAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AADH;;AAIA;EACG,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,cAAA;AADH;;AAIA;EACG,kBAAA;EACA,SAAA;EACA,WAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AADH","sourcesContent":["\n//MODAL\n.modal {\n   position: fixed;\n   top: 0;\n   left: 0;\n   right: 0;\n   bottom: 0;\n   background-color: white;\n   display: flex;\n   align-items: center;\n   justify-content: center;\n   flex-direction: column;\n}\n\n.modal .actions {\n   display: flex;\n   justify-content: center;\n   width: 400px;\n}\n\n.options .button {\n   width: 100%;\n}\n\n.img-modal {\n   display: flex;\n   position: fixed;\n   top: 0;\n   left: 0;\n   width: 100%;\n   height: 100%;\n   background-color: rgba(0, 0, 0, 0.7);\n   justify-content: center;\n   align-items: center;\n   z-index: 1000;\n}\n\n.img-modal-content {\n   background-color: #fff;\n   padding: 20px;\n   border-radius: 8px;\n   max-width: 90%;\n   max-height: 90%;\n   overflow: auto;\n}\n\n.img-close-button {\n   position: absolute;\n   top: 10px;\n   right: 10px;\n   background-color: #ccc;\n   padding: 10px;\n   border: none;\n   border-radius: 4px;\n   cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
