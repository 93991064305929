import React, {useState,useEffect, useContext} from 'react'

import DynamicList from '../../../../components/DynamicList'
import GeneralContext from  '../../../../context/GeneralContext'

function Area() {
   const [loading, setLoading] = useState(true)
   const { elements: areaAttrs, setElements: setAreaAttrs} = useContext(GeneralContext)

   useEffect(() => {
      setAreaAttrs({
         layers: [],
      })
      setLoading(false)
   },[])

   if (!loading) {
   
      const contextVariables = Object.keys(areaAttrs).reduce((acc, key) => {
         acc[key] = {
            elements: areaAttrs[key],
            setElements: (value) => setAreaAttrs((prevState) => ({ ...prevState, [key]: value }))
         }
         return acc
     }, {})

      return (
         <>
         {
            Object.entries(contextVariables).map(([name,context]) =>(
               <GeneralContext.Provider name={name} value={context}>
                  <DynamicList context="components" name={name}></DynamicList>
               </GeneralContext.Provider>
            ))
         }
         </>
      )
   }
}

export default Area