import React, { createContext, useContext, useState } from 'react'

const ValidatorContext = createContext()

export const useValidatorContext = () => useContext(ValidatorContext)

export const ValidatorProvider = ({ children }) => {
   const [validator, setValidator] = useState(false)

   const updateValidator = (status) => {
      setValidator(status)
   }

   return (
      <ValidatorContext.Provider value={{ validator, updateValidator }}>
         {children}
      </ValidatorContext.Provider>
   )
}
