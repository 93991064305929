import axios from 'axios';
import Cookies from 'js-cookie';

import {statusCheck} from './middlewares/responseChecker';
import { env } from '../config/config';

const API = env('SERVER_API')

export const getPreBomInfo = async (args) =>  {
    try {       
        
        const {idOperator, idProject, component} = args

        const res = await axios.get(`${API}/generatorBom/preBom`, {
            params: {
                idOperator,
                idProject,
                component
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
        
        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: executePreBom")
    }
}

export const checkPreBom = async (args) =>  {
    try {       
        
        const {idOperator, idProject} = args

        const res = await axios.get(`${API}/generatorBom/preBom/check`, {
            params: {
                idOperator,
                idProject
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
        
        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: executePreBom")
    }
}

export const executePreBom = async (args) =>  {
    try {

        const {idOperator, idProject} = args

        const res = await axios.get(`${API}/generatorBom/executePreBom`, {
            params: {
                idOperator,
                idProject
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
        
        if (statusCheck(res)) {
            return res.data
        }


    } catch (error) {
        console.log("ERRO: executePreBom")
    }
}

export const executeBom = async (args) =>  {
    try {

        const {idOperator,idProject,templateDate,bomType,network} = args

        const res = await axios.get(`${API}/generatorBom/executeBom`, {
            params: {
                idOperator,
                idProject,
                templateDate,
                bomType,
                network
            },
            ...JSON.parse(Cookies.get('authHeader')),
        })

        if (statusCheck(res)) {
            const { filename, data } = res.data
            const blob = b64toBlob(data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = filename
            document.body.appendChild(link)
            link.click()
    
            window.URL.revokeObjectURL(url)
            return true
        }
    } catch (error) {
        console.error('Error fetching BOM:', error);
    }
}

function b64toBlob(base64, contentType) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
}