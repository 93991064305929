import axios from 'axios';
import Cookies from 'js-cookie';

import {statusCheck} from './middlewares/responseChecker';
import { env } from '../config/config';

const API = env('SERVER_API')

export const executeValidator = async (args) => {
    try {
        const {idOperator, idProject, phase} = args

        const res = await axios.get(`${API}/validator`, {
            params: {
                idOperator,
                idProject,
                phase
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
    
        if (statusCheck(res)) {
            return res.data
        } else {
            return false
        }

    } catch (error) {
        console.log("ERRO: validator")
    }
}

export const getAnomalies = async (args) => {
    try {
        const {idOperator, idProject, phase} = args

        const res = await axios.get(`${API}/validator/results`, {
            params: {
                idOperator,
                idProject,
                phase
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
    
        if (statusCheck(res)) {
            return res.data
        } else {
            return false
        }

    } catch (error) {
        console.log("ERRO: validator")
    }
}

export const getConfigNames = async (args) => {
    try {
        const {idOperator} = args

        const res = await axios.get(`${API}/validator/configNames`, {
            params: {
                idOperator,
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
    
        if (statusCheck(res)) {
            return res.data
        } else {
            return false
        }

    } catch (error) {
        console.log("ERRO: getConfigs")
    }
}


export const getConfig = async (args) => {
    try {
        const {idOperator, configName} = args

        const res = await axios.get(`${API}/validator/config`, {
            params: {
                idOperator,
                configName
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
    
        if (statusCheck(res)) {
            return res.data
        } else {
            return false
        }

    } catch (error) {
        console.log("ERRO: validator")
    }
}

export const addConfigName = async (args) => {
    try {
        const {idOperator,configName} = args

        const res = await axios({
            url: `${API}/validator/configName`,
            method: 'POST',
            data: {
                idOperator,
                configName
            },
            ...JSON.parse(Cookies.get('authHeader')),
        })
    
        if (statusCheck(res)) {
            return res.data
        } else {
            return false
        }

    } catch (error) {
        console.log("ERRO: getConfigs")
    }
}

export const addConfig = async (args) => {
    try {
        const {idOperator,configName,config} = args

        const res = await axios({
            url: `${API}/validator/config`,
            method: 'POST',
            data: {
                idOperator,
                configName,
                config
            },
            ...JSON.parse(Cookies.get('authHeader')),
        })
    
        if (statusCheck(res)) {
            return res.data
        } else {
            return false
        }

    } catch (error) {
        console.log("ERRO: getConfigs")
    }
}

export const deleteConfig = async (args) => {
    try {
        const {idOperator,configName} = args

        const res = await axios({
            url: `${API}/validator/config`,
            method: 'DELETE',
            params: {
                idOperator,
                configName,
            },
            ...JSON.parse(Cookies.get('authHeader')),
        })
    
        if (statusCheck(res)) {
            return res.data
        } else {
            return false
        }

    } catch (error) {
        console.log("ERRO: getConfigs")
    }
}