import React, { useContext, useEffect, useState } from 'react'
import DynamicList from '../../../components/DynamicList'
import GeneralContext from  '../../../context/GeneralContext'

function Spliter() {
   const [loading,setLoading] = useState(true)
   const { elements: rule, setElements: setRule} = useContext(GeneralContext)

   useEffect(() => {
      if (!rule) {
         setRule({
            types: [],
            installations: [],
            spliterComponent: ""
         })
      }
      setLoading(false)
   }, [])


   if (!loading) {

      const contextVariables = Object.keys(rule).filter(a => a != 'spliterComponent').reduce((acc, key) => {
         acc[key] = {
            elements: rule[key],
            setElements: (value) => setRule((prevState) => ({ ...prevState, [key]: value }))
         }
         return acc
      }, {})

      const handleChange = (value, field) => {
         setRule(prevState => ({ ...prevState, [field]: value }))
      }

      return (
         <ul>
            <li>
               <label htmlFor="spliterComponent">Aplicar a <strong>Splitters</strong> contidos em:</label>
               <select className='inputBox' name="spliterComponent" id="spliterComponent" value={rule.spliterComponent} onChange={(e) => {handleChange(e.target.value, 'spliterComponent')}}>
               <option value="">-- Selecione um opção --</option>
               <option value="join">Junta</option>
               <option value="plc">Plc</option>
               </select>
            </li>
            {
            Object.entries(contextVariables).map(([name,context]) =>(
               <GeneralContext.Provider name={name} value={context}>
                  <DynamicList context="rules" name={name}></DynamicList>
               </GeneralContext.Provider>
            ))
            }
         </ul>
      )
   }
}

export default Spliter