import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { getProjectUsers, getProjectCategory, getDeletedFilesCategory, giveAccess, removeAccess, deleteProjectFile, downloadWorkProject, downloadProjectFile, extractCategoryContent, download } from '../../../../controllers/project'

import ButtonList from '../../../components/ButtonList'

import Category from './Category'
import Form from './Form'

import { useFileContext } from '../../../context/FileContext'
import { env } from '../../../../config/config'
import { useAuthContext } from '../../../context/AuthContext'

const Files = () => {
   const { idOperator, idProject } = useParams()
   const {privileges} = useAuthContext()
   const { t } = useTranslation()
   const { uploadProgress, downloadProgress, updateDownProgress } = useFileContext()

   const [category, setCategory] = useState('maps')
   const [categoryFiles, setCategoryFiles] = useState([])
   const [showFilesForm, setShowFilesForm] = useState(undefined)
   const [triggerEffect, setTriggerEffect] = useState(false) // State to trigger the useEffect
   const [downWork, setDownWork] = useState(false) // State to trigger the useEffect
   const [showDeleted,setShowDeleted] = useState(false)


   useEffect(() => {
      loadProjectCategory()
   }, [category, triggerEffect]) // Add triggerEffect to the dependencies array

   useEffect(() => {
      if (showDeleted) {
         loadDeletedFiles()
      } else {
         loadProjectCategory()
      }
   }, [showDeleted])

   const loadProjectCategory = async () => {
      const projectCategoryResult = await getProjectCategory({
         idOperator,
         idProject,
         category
      })

      if (projectCategoryResult) {
         setCategoryFiles(projectCategoryResult)
      }
   }

   const loadDeletedFiles = async () => {
      const result = await getDeletedFilesCategory({
         idOperator,
         idProject,
         category
      })

      if (result) {
         setCategoryFiles(result)
      }
   }

   const handleFilterClick = (selectedFilter) => {
      setCategory(selectedFilter)
   }

   const deleteFile = async (category, file) => {
      const res = await deleteProjectFile({
         idOperator,
         idProject,
         category,
         file
      })

      if (res) {
         setCategoryFiles(categoryFiles.filter(f => f.name !== file))
      }
   }

   const downloadFile = async (category, file) => {
      await download({
         idOperator,
         idProject,
         category,
         file,
      }, (loaded) => {
         const progress = Math.round((loaded.loaded / loaded.total) * 100)
         updateDownProgress(progress)
      })

      updateDownProgress(undefined)
   }

   const downloadWork = async () => {
      setDownWork(true)
      await downloadWorkProject({
         idOperator,
         idProject,
      }, (loaded) => {
         const progress = Math.round((loaded.loaded / loaded.total) * 100)
      })
      setDownWork(false)
   }

   const cancelMethod = (e) => {
      setShowFilesForm(undefined)
   }

   const finishUpload = () => {
      setCategory(category)
      setShowFilesForm(undefined)
      setTriggerEffect(prevState => !prevState) // Toggle the triggerEffect state to force useEffect
   }

   return (
      <>
         <div className="grid-two">
            <div className="grid-column">
               <div className='column-title'>
                  <h2>Categorias</h2>
               </div>
               <div className='button-tabulation'>
                  <ButtonList
                     filters={['maps','synoptics','tbsLink','tbsConfig','tbsAddress']}
                     handleFilterClick={handleFilterClick}
                     selectedFilter={category}
                  />
               </div>
               <hr></hr>
               <ul className='button-list-ver'>
                  <li className='button-item'>
                     <button 
                        className="button" 
                        onClick={_ => downloadWork()}
                        disabled={downWork}>Transferir projeto de dev</button>
                  </li>
               </ul>
            </div>
            <div className='grid-column'>
               <div className='column-title'>
                  <h2>{t(`tabs.${category}`)}</h2>
               </div>
               {
                  showFilesForm == category ? (
                     <Form
                        category={category}
                        idProject={idProject}
                        idOperator={idOperator}
                        cancelMethod={cancelMethod}
                        finishUpload={finishUpload}
                     />
                  ) : (
                     uploadProgress !== undefined ? (
                        <div className='container'>
                           <span>Upload em progresso: {uploadProgress}%</span>
                        </div>
                     ) : (
                        <ul className='button-list-ver'>
                           <li className='button-item'>
                                 <button className="button" onClick={_ => setShowFilesForm(category)}>Submeter ficheiros</button>
                           </li>
                        </ul>
                     )
                  )
               }
               <hr></hr>
               <div>
                  {
                     privileges.includes(env('ADMIN')) && (
                        <div className='switch-container'>
                           <span className='switch-label'>Removidos</span>
                           <label className="switch">
                              <input type="checkbox" onClick={_ => setShowDeleted(!showDeleted)}/>
                              <span className="slider round"></span>
                           </label>
                        </div>
                     )
                  }
               </div>
               {
                  categoryFiles.length > 0 ? (
                     <Category
                        category={category}
                        categoryFiles={categoryFiles}
                        deleteFile={deleteFile}
                        downloadFile={downloadFile}
                        showDeleted={showDeleted}
                     />
                  ) : (
                     <h3>Não existem ficheiros da categoria...</h3>
                  )
               }
            </div>
         </div>
      </>
   )
}

export default Files
