import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react'
import DynamicList from '../../../../components/DynamicList'
import GeneralContext from  '../../../../context/GeneralContext'

function Post() {
    const [loading, setLoading] = useState(true)
    const { elements: postAttrs, setElements: setPostAttrs} = useContext(GeneralContext)
 
    useEffect(() => {
        setPostAttrs({
            blocks: [],
            layers: [],
            ids: [],
            types: [],
            heights: []
       })
       setLoading(false)
    },[])
 
    if (!loading) {

        const contextVariables = Object.keys(postAttrs).reduce((acc, key) => {
            acc[key] = {
               elements: postAttrs[key],
               setElements: (value) => setPostAttrs((prevState) => ({ ...prevState, [key]: value }))
            }
            return acc
         }, {})
      
         return (
            <>
                {
                    Object.entries(contextVariables).map(([name, context]) => (
                        <GeneralContext.Provider key={name} value={context}>
                            <DynamicList context="components" name={name}></DynamicList>
                        </GeneralContext.Provider>
                    ))
                }
            </>
        )
    }

}

export default Post
