import React, { useState, useEffect, useCallback, useContext } from 'react'
import DynamicList from '../../../../components/DynamicList'
import GeneralContext from  '../../../../context/GeneralContext'

function Trench() {
   const [loading, setLoading] = useState(true)
   const { elements: trenchAttrs, setElements: setTrenchAttrs} = useContext(GeneralContext)

   useEffect(() => {
      setTrenchAttrs({
         layers: [],
         blocks: [],
         meters: [],
         metersAS: [],
         metersPA: [],
         metersTE: []
      })
      setLoading(false)
   },[])

   if (!loading) {

      const contextVariables = Object.keys(trenchAttrs).reduce((acc, key) => {
         acc[key] = {
            elements: trenchAttrs[key],
            setElements: (value) => setTrenchAttrs((prevState) => ({ ...prevState, [key]: value }))
         }
         return acc
      }, {})
  
      return (
         <>
            {
               Object.entries(contextVariables).map(([name,context]) => (
                  <GeneralContext.Provider key={name} value={context}>
                     <DynamicList context="components" name={name}></DynamicList>
                  </GeneralContext.Provider>
               ))
            }
         </>
      )
   }
}

export default Trench
