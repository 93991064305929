import React, { useContext, useEffect, useState } from 'react'
import DynamicList from '../../../components/DynamicList'
import GeneralContext from  '../../../context/GeneralContext'

function Allocation() {
   const [loading,setLoading] = useState(true)
   const { elements: rule, setElements: setRule} = useContext(GeneralContext)

   useEffect(() => {
      if (!rule) {
         setRule({
            types: [],
            applications: [],
            cells: [],
            connections: [],
            min: undefined,
            max: undefined
         })
      }
      setLoading(false)
   }, [])


   if (!loading) {
      const contextVariables = Object.keys(rule).filter(a => a != 'min' &&  a != 'max').reduce((acc, key) => {
         acc[key] = {
            elements: rule[key],
            setElements: (value) => setRule((prevState) => ({ ...prevState, [key]: value }))
         }
         return acc
      }, {})

      const handleChange = (value, field) => {
         setRule(prevState => ({ ...prevState, [field]: value }))
      }
   
      return (
         <>
         {
            Object.entries(contextVariables).map(([name,context]) =>(
               <GeneralContext.Provider name={name} value={context}>
                  <DynamicList context="rules" name={name}></DynamicList>
               </GeneralContext.Provider>
            ))
         }
         <li>
            <label htmlFor="min"><strong>Mínimo de alocações</strong>:</label>
            <input className='inputBox' type='number' value={rule.min >= 0 ? rule.min : undefined} onChange={e => handleChange(e.target.value,'min')}></input>
         </li>
         <li>
            <label htmlFor="max"><strong>Máximo de alocações</strong>:</label>
            <input className='inputBox' type='number' value={rule.max >= 0 ? rule.max : undefined} onChange={e => handleChange(e.target.value,'max')}></input>
         </li>
      </>
      )
   }
}

export default Allocation