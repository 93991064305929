import React, { useState, useContext } from 'react'
import { Button, Confirm } from 'semantic-ui-react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { deleteOperator, cloneOperator } from '../../../../controllers/operator'
import { useAuthContext } from '../../../context/AuthContext'
import { env } from '../../../../config/config'


function Options() {
   const {idOperator} = useParams()
   const navigate = useNavigate()
   const {privileges} = useAuthContext()

   const [showCloneForm, setShowCloneForm] = useState(false)
   const [cloneName, setCloneName] = useState('')

   //Confirmation Modal
   const [open, setOpen] = useState(false)

   const handleDelete = async () => {
      const res = await deleteOperator({
         idOperator
      })

      if (res) {
         navigate('/gestOperators')
      }
   }

   const handleClonar = async () => {
      if (cloneName != '') {
         const res = await cloneOperator({
               idOperator,
               cloneName
         })

         if (res) {
               navigate('/gestOperators')
         }
      } else {
         toast.info("Formulário incompleto!")
      }
   }


   return (
      <>      
      {
         showCloneForm ? (
            <>
            <div className='form-container'>
                  <ul className='form-strait'>
                     <li>
                        <label>Nome do clone:</label>
                        <input className="inputBox" type="text" onChange={(e) => setCloneName(e.target.value)}/>
                     </li>
                  </ul>
               </div>
            <ul className='button-list-hor'>
               <li className='button-item'>
                  <button className="button" onClick={() => handleClonar()}>Gravar</button>
               </li>
               <li className='button-item'>
                  <button className="button" onClick={_ => setShowCloneForm(false)}>Cancelar</button>
               </li>
            </ul>
            </>
         ) : (
            <ul className='button-list-hor'>
               <li className='button-item'>
                  <button className='button' onClick={_ => setShowCloneForm(true)}>Clonar</button>
               </li>
            </ul>
         )
      }
      {
         privileges.includes(env('ADMIN')) && (
            <>
            <ul className='button-list-ver'>
               <li className='button-item'>
                  <Button className='button delete' onClick={_ => setOpen(true)}>Apagar</Button>
               </li>
            </ul>
            <Confirm
               open={open}
               onCancel={() => setOpen(false)}
               onConfirm={() => {handleDelete(); setOpen(false)}}
               content={
                  <h3>Pretende apagar a operadora?</h3>
               }
               confirmButton={{
                  content: "Confirmar",
                  className: "button-item delete"
               }}
               cancelButton={{
                  content: "Cancelar",
                  className: "button-item"
               }}
               className="button-list-hor confirm-modal"
            />
            </>
         )
      }
   </>
   )
}

export default Options