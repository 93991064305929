import React from 'react'
import DynamicList from '../../../components/DynamicList'
import GeneralContext from  '../../../context/GeneralContext'

function Tube() {
   return (
      <div>Nota: Não possui propriedades de especificação</div>
   )
}

export default Tube