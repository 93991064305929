import React, { createContext, useContext, useState } from 'react'

const SynContext = createContext()

export const useSynContext = () => useContext(SynContext)

export const SynProvider = ({ children }) => {
   const [syn, setSyn] = useState(false)

   const updateSyn = (status) => {
      setSyn(status)
   }

   return (
      <SynContext.Provider value={{ syn, updateSyn }}>
         {children}
      </SynContext.Provider>
   )
}
