// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainpage-item {
  padding: 16px;
  background: #eee;
  color: black;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  width: 100%;
  border: 2px solid transparent;
}

.mainpage-item:hover {
  background: white;
  color: #006FFF;
  cursor: pointer;
  text-decoration: underline;
  border: 2px solid #006FFF;
}

.main-container {
  margin: 15px;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/Main.scss"],"names":[],"mappings":"AACA;EACG,aAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,6BAAA;AAAH;;AAIA;EACG,iBAAA;EACA,cAAA;EACA,eAAA;EACA,0BAAA;EACA,yBAAA;AADH;;AAIA;EACG,YAAA;EACA,kBAAA;AADH","sourcesContent":["\n.mainpage-item {\n   padding: 16px;\n   background: #eee;\n   color: black;\n   border-radius: 10px;\n   text-align: center;\n   text-decoration: none;\n   width: 100%;\n   border: 2px solid transparent;\n   \n}\n\n.mainpage-item:hover {\n   background: white;\n   color: #006FFF;\n   cursor: pointer;\n   text-decoration: underline;\n   border: 2px solid #006FFF;\n}\n\n.main-container {\n   margin: 15px;\n   position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
