import React, {useContext} from 'react'
import { useTranslation } from 'react-i18next'

import generalContext from '../context/GeneralContext'


function DynamicList({context, name}) {
   const { t } = useTranslation()

   const { 
      elements,
      setElements,
   } = useContext(generalContext)
   
   const handleElement = (index, event) => {
      const values = [...elements]
      values[index] = event.target.value
      setElements(values)
   }

   const handleAddElement = () => {
      const values = [...elements]
      values.push("")
      setElements(values)
   }

   const handleRemoveElement = (index) => {
      const values = [...elements]
      values.splice(index, 1)
      setElements(values)
   }

   return (
      <>
      <ul className='form-component'>
         {
            elements && elements.length > 0 && elements.map((element, index) => (
               <li key={index}>
                  <label>{t(`components.labels.${name}`)}:</label>
                  <div className='inline-container'>
                     <input 
                        className="inputBox"
                        type={
                           (context == 'rules' && (name == "heights" || name == "capacities")) ? 'Number' : 'Text'
                        }
                        value={element}
                        onChange={(event) => handleElement(index, event)}
                     />
                     <ul className='button-list-hor'>
                        <li className='button-item'>
                           <button className="button" onClick={() => handleRemoveElement(index)}>Remover</button>
                        </li>
                     </ul>
                  </div>
               </li>
            ))
         }
      </ul>
      <ul className='button-list-ver'>
         <li className='button-item'>
            <button className="button" onClick={handleAddElement}>Adicionar <strong>{t(`components.buttons.${name}`)}</strong></button>
         </li>
      </ul>
      <hr></hr>
   </>
   )
}

export default DynamicList