// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-two {
  display: grid;
  grid-template-columns: 35% minmax(0, 65%);
  gap: 30px;
}

.grid-three {
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  gap: 20px;
}

.column-title {
  margin: 0px 0px 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/functional/Grids.scss"],"names":[],"mappings":"AAAA;EACG,aAAA;EACA,yCAAA;EACA,SAAA;AACH;;AAEA;EACG,aAAA;EACA,oCAAA;EACA,SAAA;AACH;;AAKA;EACG,sBAAA;AAFH","sourcesContent":[".grid-two {\n   display: grid;\n   grid-template-columns: 35% minmax(0, 65%);\n   gap: 30px;\n}\n\n.grid-three {\n   display: grid;\n   grid-template-columns: 1fr 1fr 0.5fr; \n   gap: 20px;\n}\n\n.grid-column {\n}\n\n.column-title {\n   margin: 0px 0px 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
