import React from 'react'

function Loading() {
   return (
      <div className='loading'>
         <div className='loading-icon'></div>
      </div>
   )
}

export default Loading