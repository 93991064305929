import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'

import { getMaterial } from '../../../controllers/template'

function Material() {
    const {idOperator, templateDate, idMaterial} = useParams()
    
    const [material, setMaterial] = useState()

    useEffect(_ => {
        loadMaterial()
    }, [])

    const loadMaterial = async () => {

        const materialResult = await getMaterial({ 
            idOperator,
            templateDate,
            idMaterial,
        });

        
        if (materialResult) {
            setMaterial(materialResult)
        }
    }

    return (
        <>
        <div className='contentTitle'>
            <h2>Material</h2>
        </div>
        <div className='content'>
            {
                material && (
                    <ul>
                        <li><strong>Id</strong>: {material.id}</li>
                        <li><strong>Nome</strong>: {material.name}</li>
                    </ul>
                )
            }
        </div>
        </>
    )
}

export default Material