// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background: lightgray;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: black;
  text-align: center;
  font-size: 14px;
  padding: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui/Footer.scss"],"names":[],"mappings":"AACA;EACI,qBAAA;EACA,eAAA;EACA,OAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;AAAJ","sourcesContent":["\n.footer {\n    background: lightgray;\n    position: fixed;\n    left: 0;\n    bottom: 0;\n    width: 100%;\n    color: black;\n    text-align: center;\n    font-size: 14px;\n    padding: 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
