import React, { useState, useEffect, useContext } from 'react'
import DynamicList from '../../../../components/DynamicList'
import GeneralContext from  '../../../../context/GeneralContext'

function Tube() {
    const [loading, setLoading] = useState(true)
    const { elements: tubeAttrs, setElements: setTubeAttrs} = useContext(GeneralContext)

    useEffect(() => {
        setTubeAttrs({
            transitionCable: '',
            transitionComponents: [],
            blocks: [],
            ids: [],
            layers: [],
        })
        setLoading(false)
    },[])

    if (!loading) {

        const contextVariables = Object.keys(tubeAttrs).filter(a => a != 'transitionCable').reduce((acc, key) => {
            acc[key] = {
               elements: tubeAttrs[key],
               setElements: (value) => setTubeAttrs((prevState) => ({ ...prevState, [key]: value }))
            }
            return acc
         }, {})

        const handleChange = (e, field) => {
            const { value } = e.target
            setTubeAttrs(prevState => ({ ...prevState, [field]: value }))
        }

        return (
            <ul>
                <li>
                    <label>Tipo do <strong>instalação</strong> do cabo:</label>
                    <select className="inputBox" name="transitionCable" id="transitionCable" value={tubeAttrs.transitionCable} onChange={(e) => handleChange(e, 'transitionCable')}>
                        <option value="">-- Selecione uma opção --</option>
                        <option value="fachada">Fachada</option>
                        <option value="conduta">Conduta</option>
                        <option value="aerio">Aério</option>
                    </select>
                </li>
                <hr></hr>
                {
                    Object.entries(contextVariables).map(([name,context]) => (
                        <GeneralContext.Provider key={name} value={context}>
                            <DynamicList context="components" name={name}></DynamicList>
                        </GeneralContext.Provider>
                    ))
                }
            </ul>
        )
    }
}

export default Tube
