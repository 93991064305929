import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

function InlineButton({ type, handle, to, icon, text }) {
   const commonContent = (
      <div className='button-inline'>
         {icon && <span className='button-icon'>{icon}</span>}
         <span className='button-text'>{text}</span>
      </div>
   )

   return (
      <>
         {type === 'link' && <Link className="button" to={to}>{commonContent}</Link>}
         {type === 'button' && <button className="button" onClick={handle}>{commonContent}</button>}
      </>
   )
}

export default InlineButton