import React, {useEffect, useState, useContext} from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getOperator, deleteOperator, cloneOperator } from '../../../controllers/operator'
import ButtonTabs from '../../components/ButtonTabs'
import Components from './components/Components'
import Rules from './components/Rules'
import Options from './components/Options'
import Templates from './components/Templates'
import Materials from './components/Materials'
import Configs from './components/Configs'


function Operator() {
   const {idOperator} = useParams()

   const [operator, setOperator] = useState({})
   
   const [option,setOption] = useState(
      localStorage.getItem('operatorOption') || undefined
   )

   useEffect(_ => {
      loadOperator()
   }, [])

   const loadOperator = async () => {
      const operatorResult = await getOperator({
         idOperator
      })

      if (operatorResult) {
         setOperator(operatorResult)
      } 
   }

   const handleFilterClick = (selectedFilter) => {
      setOption(selectedFilter)
      localStorage.setItem('operatorOption', selectedFilter)
   }

   return (
      <>
       <div className="contentTitle">
         <h2>Operadora: {operator.name}</h2>
      </div>
      <div className="content">
         <ButtonTabs
            filters={['components','rules','materials','templates','validator','options']}
            handleFilterClick={handleFilterClick}
            selectedFilter={option}
         />
         <hr></hr>
         <div className='container'>
            {
               option == 'components' && <Components idOperator={idOperator}/>
            }
            {
               option == 'rules' && <Rules idOperator={idOperator}/>
            }
            {
               option == 'templates' && <Templates idOperator={idOperator}/>
            }
            {
               option == 'options' && <Options idOperator={idOperator}/>
            }
            {
               option == 'materials' && <Materials idOperator={idOperator}/>
            }
            {
               option == 'validator' && <Configs idOperator={idOperator}/>
            }
         </div>
      </div>
      </>
      )
  }

export default Operator