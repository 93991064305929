import React, { useState, useEffect, useContext } from 'react'
import GeneralContext from '../../../../context/GeneralContext'

function Table() {
    const [loading, setLoading] = useState(true)
    const { elements: tableAttrs, setElements: setTableAttrs} = useContext(GeneralContext)
 
    useEffect(() => {
        setTableAttrs({
            lineStart: null,
            type: null,
            application: null,
            cell: null,
            connection: null,
            destiny: null,
            secondaryDestiny: "",
            secondaryConnection: ""
       })
       setLoading(false)
    },[])
 
    if (!loading) {

        const handleChange =(e, field) => {
            const { value } = e.target
            setTableAttrs(prevState => ({ ...prevState, [field]: value }))
        }
    
        return (
            <ul className='form'>
                <li>
                    <label>Número da linha de <strong>Inicio</strong> do conteúdo:</label>
                    <input className="inputBox" type="number" value={tableAttrs.lineStart} onChange={e => handleChange(e, 'lineStart')}></input>
                </li>
                <li>
                    <label>Número da coluna do <strong>Tipo</strong>:</label>
                    <input className="inputBox" type="number" value={tableAttrs.type} onChange={e => handleChange(e, 'type')}></input>
                </li>
                <li>
                    <label>Número da coluna da <strong>Aplicação</strong>:</label>
                    <input className="inputBox" type="number" value={tableAttrs.application} onChange={e => handleChange(e, 'application')}></input>
                </li>
                <li>
                    <label>Número da coluna da <strong>Célula</strong>:</label>
                    <input className="inputBox" type="number" value={tableAttrs.cell} onChange={e => handleChange(e, 'cell')}></input>
                </li>
                <li>
                    <label>Número da coluna do  <strong>Conector</strong>:</label>
                    <input className="inputBox" type="number" value={tableAttrs.connection} onChange={e => handleChange(e, 'connection')}></input>
                </li>
                <li>
                    <label>Número da coluna do <strong>Destino</strong>:</label>
                    <input className="inputBox" type="number" value={tableAttrs.destiny} onChange={e => handleChange(e, 'destiny')}></input>
                </li>
                <li>
                    <label>Identificador de <strong>secundária</strong> na coluna <strong>Destino</strong>:</label>
                    <input className="inputBox" type="text" value={tableAttrs.secondaryDestiny} onChange={e => handleChange(e, 'secondaryDestiny')}></input>
                </li>
                <li>
                    <label>Identificador de <strong>secundária</strong> na coluna <strong>Conector</strong>:</label>
                    <input className="inputBox" type="text" value={tableAttrs.secondaryConnection} onChange={e => handleChange(e, 'secondaryConnection')}></input>
                </li>
            </ul>
        )
    }
}

export default Table
