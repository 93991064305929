import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GeneralContext from  '../../context/GeneralContext'

import { getRule, postRule, putRule, deleteRule } from '../../../controllers/rule'

import Join from './components/Join'
import Plc from './components/Plc'
import Cable from './components/Cable'
import Spliter from './components/Splitter'
import Tube from './components/Tube'
import Allocation from './components/Allocation'
import Trench from './components/Trench'
import Post from './components/Post'

function Rule() {
   const {t} = useTranslation()
   const navigate = useNavigate()
   const {idOperator, component, idRule} = useParams()

   const [loading,setLoading] = useState(true)
   const [rule,setRule] = useState(undefined)

   const contextVariables = {
      elements: rule,
      setElements: setRule
   }


   useEffect(_ => {
      if (idRule != null) {
         loadRuleToEdit(idOperator)
      }
      setLoading(false)
   }, [component, idOperator, idRule])


   const loadRuleToEdit = async () => {
      var ruleResult = await getRule({
         idOperator,
         idRule,
         component,
      })

      if (ruleResult == null) {
         navigate(`/operator/${idOperator}`)
         return
      }

      delete ruleResult.modifiedAt
      delete ruleResult.subRules
      delete ruleResult._id

      setRule(ruleResult)
   }
   

   const handleSubmit = async (event) => {
      event.preventDefault()

      switch (component) {
         case 'join':
         case 'plc':
            rule.types = rule.types.filter(t => t != "")
            rule.installations = rule.installations.filter(t => t != "")
            break
         case 'cable':
            rule.capacities = rule.capacities.filter(t => t != "")
            rule.installations = rule.installations.filter(t => t != "")
            rule.cumulate = rule.cumulate === 'true'
            break
         case 'post':
            rule.heights = rule.heights.filter(t => t != "")
            rule.types = rule.types.filter(t => t != "")
            break
         case 'trench':
            rule.installations = rule.installations.filter(t => t != "")
            break
         case 'allocation':
            rule.types = rule.types.filter(t => t != "")
            rule.applications = rule.applications.filter(t => t != "")
            rule.cells = rule.cells.filter(t => t != "")
            rule.connections = rule.connections.filter(t => t != "")
            rule.min = !rule.min ? -1 : rule.min
            rule.max = !rule.max ? -1 : rule.max
            break
         case 'spliter':
            rule.types = rule.types.filter(t => t != "")
            rule.spliterComponent = rule.spliterComponent
            rule.installations = rule.installations.filter(t => t != "")
            break
         default:
            break
      }

      var res = false
      if (idRule != undefined) {
         res = await putRule({ idOperator, component, idRule, rule })
      } else {
         res = await postRule({ idOperator, component, rule })
      }

      if (res) {
         navigate(`/operator/${idOperator}`)
      }
   }

   const handleDelete = async () => {
      const res = await deleteRule({
         idOperator,
         idRule,
         component
      })
      
      if (res) {
         navigate(`/operator/${idOperator}`)
      }
   }

   if (!loading) {
      return (
         <>
         <div className='contentTitle'>
            <h2>Regra para {t(`tabs.${component}`)}</h2>
         </div>
         <div className='content'>
            <div className='form-container'>
               <ul className='form-strait'>
                  <GeneralContext.Provider value={contextVariables}>
                  {
                     component === "join" ? <Join/> :
                     component === "plc" ? <Plc/> :
                     component === "cable" ? <Cable/> : 
                     component === "spliter" ? <Spliter/> :
                     component === "tube" ? <Tube/> : 
                     component === "post" ? <Post/> : 
                     component === "trench" ? <Trench/> : 
                     component === "allocation" ? <Allocation/> : 
                     <></>
                  }
                  </GeneralContext.Provider>
               </ul>
            </div>
            <ul className='button-list-hor'>
               <li className='button-item'>
                  <button className="button" onClick={handleSubmit}>Gravar</button>
               </li>
               {
                  idRule && <li className='button-item'><button className="button delete" onClick={handleDelete}>Apagar</button></li>
               }
            </ul>
         </div>
         </>
      )
   }
}

export default Rule