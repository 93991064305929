import React, { useState, useEffect, useContext } from 'react'
import DynamicList from '../../../../components/DynamicList'
import GeneralContext from  '../../../../context/GeneralContext'

function Plc() {
   const [loading, setLoading] = useState(true)
   const { elements: plcAttrs, setElements: setPlcAttrs} = useContext(GeneralContext)

   useEffect(() => {
      setPlcAttrs({
         layers: [],
         layersSyn: [],
         idsMap: [],
         idsSyn: [],
         spliters: [],
         blocks: [],
         types: [],
         installations: [],
         origins: [],
         uas: [],
         secondaries: [],
         deadFibers: [],
         reservedFibers: [],
         activeFibers: [],
      })
      setLoading(false)
   },[])

   if (!loading) {

      const contextVariables = Object.keys(plcAttrs).reduce((acc, key) => {
         acc[key] = {
            elements: plcAttrs[key],
            setElements: (value) => setPlcAttrs((prevState) => ({ ...prevState, [key]: value }))
         }
         return acc
      }, {})

      return (
         <>
            {Object.entries(contextVariables).map(([name, context]) => (
               <GeneralContext.Provider key={name} value={context}>
                  <DynamicList context="components" name={name}></DynamicList>
               </GeneralContext.Provider>
            ))}
         </>
      )
   }
}

export default Plc
