import React, { createContext, useContext, useState } from 'react'

const FileContext = createContext()

export const useFileContext = () => useContext(FileContext)

export const FileProvider = ({ children }) => {
   const [uploadProgress, setUploadProgress] = useState(undefined)
   const [downloadProgress, setDownloadProgress] = useState(undefined)

   const updateUpProgress = (progress) => {
      setUploadProgress(progress)
   }

   const updateDownProgress = (progress) => {
      setDownloadProgress(progress)
   }

   return (
      <FileContext.Provider value={{ 
         uploadProgress,
         updateUpProgress,
         downloadProgress,
         updateDownProgress
      }}>
         {children}
      </FileContext.Provider>
   )
}
