import axios from 'axios';
import Cookies from 'js-cookie';

import {statusCheck} from './middlewares/responseChecker';
import { env } from '../config/config';

const API = env('SERVER_API')

export const getComponent = async (args) => {
    try {
        const {idOperator, type} = args

        const res = await axios.get(`${API}/component/`, {
            params: {
               idOperator,
               type
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }
    } catch (error) {
        console.log("ERRO: Get Component")
    }
}

export const saveComponent = async (args) => {
    try {
        const {idOperator, type, component} = args
        
        const res = await axios.post(`${API}/component/`, {
            idOperator,
            type,
            component
        }, JSON.parse(Cookies.get('authHeader')));
        
        return statusCheck(res)

    } catch (error) {
        console.log("ERRO: Save Component")
    }
}