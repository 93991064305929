import React, { useState } from 'react'
import Logs from './Logs'


function GestLogs() {

   const [showLogErrors,setShowLogErrors] = useState(false)

   return (
      <>
      <div className='contentTitle'>
         <h2>Histórico</h2>
      </div>
      <div className='switch-container'>
         <span className='switch-label'>Tarefas</span>
         <label className="switch">
            <input type="checkbox" onClick={_ => setShowLogErrors(!showLogErrors)}/>
            <span className="slider round"></span>
         </label>
         <span className='switch-label'>Erros</span>
      </div>
      <div className="content">
         <div className='container'>
            <Logs type={showLogErrors ? 'error' : 'task'}></Logs>
         </div>
      </div>
      </>
   )
}

export default GestLogs
