import React, { useState, useEffect, useContext } from 'react'
import DynamicList from '../../../../components/DynamicList'
import GeneralContext from  '../../../../context/GeneralContext'

function Cable() {
    const [loading, setLoading] = useState(true)
    const { elements: cableAttrs, setElements: setCableAttrs} = useContext(GeneralContext)
 
    useEffect(() => {
        setCableAttrs({
            ids: [],
            capacities: [],
            totalMeters: [],
            installations: [],
            metersA: [],
            metersF: [],
            metersC: [],
            secondaries: [],
            layersA: [],
            layersF: [],
            layersC: []
       })
       setLoading(false)
    },[])
 
    if (!loading) {

        const contextVariables = Object.keys(cableAttrs).reduce((acc, key) => {
            acc[key] = {
               elements: cableAttrs[key],
               setElements: (value) => setCableAttrs((prevState) => ({ ...prevState, [key]: value }))
            }
            return acc
        }, {})

        return (
            <>
                {
                    Object.entries(contextVariables).map(([name,context]) =>(
                        <GeneralContext.Provider name={name} value={context}>
                            <DynamicList context="components" name={name}></DynamicList>
                        </GeneralContext.Provider>
                    ))
                }
               
            </>
        )
    }
}

export default Cable
