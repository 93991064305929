import axios from 'axios';
import Cookies from 'js-cookie';

import {statusCheck} from './middlewares/responseChecker';
import { env } from '../config/config';

const API = env('SERVER_API')

export const lock = async (idOperator) => {
    try {
        const res = await axios.get(`${API}/locker/lock/`, {
            params: {
               idOperator,
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
        
        return statusCheck(res)
    
    } catch (error) {
        console.error(error);
    }
};

export const unlock = async () => {
    try {

        const res = await axios.get(`${API}/locker/unlock/`, {
            ...JSON.parse(Cookies.get('authHeader'))
        })
        
        return statusCheck(res)
    
    } catch (error) {
        console.error(error);
    }
};