import React from 'react'
import { useTranslation } from 'react-i18next'

function ButtonTabs({ filters, handleFilterClick, selectedFilter }) {
   const { t } = useTranslation();

   return (
      <ul className='button-list-hor-wrapped'>
      {
         filters.map(filter => (
            <li className='button-item' key={filter}>
               <button className={`button ${selectedFilter === filter ? 'selected' : ''}`} onClick={() => handleFilterClick(filter)} >
                  {t(`tabs.${filter}`) !== `tabs.${filter}` ? t(`tabs.${filter}`) : filter}
               </button>
            </li>
         ))
      }
      </ul>
   )
}

export default ButtonTabs