import axios from 'axios'
import Cookies from 'js-cookie'

import {statusCheck} from './middlewares/responseChecker'
import { env } from '../config/config';

const API = env('SERVER_API')

export const upload = async (formData, uploadProgress) => {
    try {
        const res = await axios.post(`${API}/project/upload`, formData, {
            ...JSON.parse(Cookies.get('authHeader')),
            onUploadProgress: uploadProgress
        })

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: getProjects")
    }
}

export const download = async (args, downloadProgress) => {
    try {
        const {idOperator, idProject, category, file} = args

        const controller = new AbortController()
        const signal = controller.signal

        const res = await axios({
            url: `${API}/project/download`,
            method: 'GET',
            params: {
                idOperator,
                idProject,
                category,
                file
            },
            responseType: 'blob',
            ...JSON.parse(Cookies.get('authHeader')),
            onDownloadProgress: downloadProgress,
            signal
        })

        if (statusCheck(res)) {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', file)
            document.body.appendChild(link)
            link.click()
            window.URL.revokeObjectURL(url)
            
            return true
        } else {
            controller.abort()
            return false
        }

    } catch (error) {
        console.log("ERRO: getProjects")
    }
}


export const downloadWorkProject = async (args, downloadProgress) => {
    try {
        const {idOperator, idProject} = args

        const controller = new AbortController()
        const signal = controller.signal

        const res = await axios({
            url: `${API}/project/downloadWorkProject`,
            method: 'GET',
            params: {
                idOperator,
                idProject,
            },
            responseType: 'blob',
            ...JSON.parse(Cookies.get('authHeader')),
            onDownloadProgress: downloadProgress,
            signal
        })

        if (statusCheck(res)) {
            const blob = new Blob([res.data], { type: 'application/zip' })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = `project.zip` 
            document.body.appendChild(link)
      
            link.click()
      
            window.URL.revokeObjectURL(url)
            document.body.removeChild(link)
            
            return true
        } else {
            controller.abort()
            return false
        }

    } catch (error) {
        console.log("ERRO: getProjects")
    }
}

export const getProjects = async (args) => {
    try {
        const {idOperator} = args

        const res = await axios.get(`${API}/project/getProjects`, {
            params: {
               idOperator
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: getProjects")
    }
}

export const getProjectsByUser = async (args) => {
    try {
        const {name} = args
        
        const res = await axios.get(`${API}/project/getProjectsByUser`, {
            params: {
                userName: name
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: getAllProjects")
    }
}

export const addProject = async (args) => {
    try {
        const {idOperator, projectName} = args

        const res = await axios.post(`${API}/project/addProject`, {
            idOperator,
            projectName
        }, JSON.parse(Cookies.get('authHeader')));

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: addProject")
    }
}

export const getProject = async (args) => {
    try {
        const {idOperator, idProject} = args

        const res = await axios.get(`${API}/project/getProject`, {
            params: {
               idOperator,
               idProject
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: getProject")
    }
}

export const getProjectUsers = async (args) => {
    try {
        const {idOperator, idProject} = args

        const res = await axios.get(`${API}/project/getProjectUsers`, {
            params: {
               idOperator,
               idProject
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: getProjectUsers")
    }
}

export const getProjectCategory = async (args) => {
    try {
        const {idOperator, idProject, category} = args

        const res = await axios.get(`${API}/project/getCategory`,  {
            params: {
               idOperator,
               idProject,
               category
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: getProjectCategory")
    }
}

export const getDeletedFilesCategory = async (args) => {
    try {
        const {idOperator, idProject, category} = args

        const res = await axios.get(`${API}/project/getDeletedFilesCategory`,  {
            params: {
               idOperator,
               idProject,
               category
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: getDeletedFilesCategory")
    }
}

export const addProjectFiles = async (args) => {
    try {
        const {idOperator, idProject, category, files} = args

        const res = await axios.put(`${API}/project/${idOperator}`, {
            idProject,
            category,
            files
        }, JSON.parse(Cookies.get('authHeader')))
        
        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: addProjectFiles")
    }
}

export const deleteProject = async (args) => {
    try {
        const {idOperator,idProject} = args
        const res = await axios.delete(`${API}/project/deleteProject`, {
            params: {
               idOperator,
               idProject
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: deleteProject")
    }
}

export const deleteProjectFile = async (args) => {
    try {
        const {idOperator,idProject,category,file} = args
        const res = await axios.delete(`${API}/project/deleteFile`, {
            params: {
               idOperator,
               idProject,
               category,
               file
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: deleteProject")
    }
}


export const downloadProjectFile = async (args) => {
    try {
        const {idOperator,idProject,category,file,downloadType} = args

        const res = await axios.post(`${API}/project/download`, {
            idOperator,
            idProject,
            category,
            file,
            downloadType
        },{
            'Content-Type': 'application/json',
            ...JSON.parse(Cookies.get('authHeader')),
            responseType: 'arraybuffer',
            onDownloadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted)
            }
        })

        if (statusCheck(res) && downloadType != 2) {
            const blob = new Blob([res.data], { type: 'application/dxf' })

            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = file
            document.body.appendChild(link)
            link.click()
      
            window.URL.revokeObjectURL(link.href)
            return true
        
        } else if (statusCheck(res) && downloadType == 2) {
            const base64Image = 'data:image/pngbase64,' + btoa(String.fromCharCode.apply(null, new Uint8Array(res.data)))
            return base64Image

        } else {
            return false
        }
        
    } catch (error) {
        console.log("ERRO: dowloadProjectFile")
    }
}


export const addUserAccess = async (args) => {
    try {
        const {idOperator, idProject, user} = args
        const res = await axios.put(`${API}/project/addUserAccess`, {
            idOperator,
            idProject,
            user
        }, JSON.parse(Cookies.get('authHeader')))

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: giveAccess")
    }
}

export const removeUserAccess = async (args) => {
    try {
        const {idOperator, idProject, user} = args

        const res = await axios.put(`${API}/project/remUserAccess`, {
            idOperator,
            idProject,
            user
        }, JSON.parse(Cookies.get('authHeader')))

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: removeAccess")
    }
}

export const extractCategoryContent = async (args) => {
    try {
        const {	idOperator, idProject, category} = args

        
        const res = await axios.get(`${API}/project/extract`,  {
            params: {
               idOperator,
               idProject,
               category
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data
        }

    } catch (error) {
        console.log("ERRO: extractCategoryContent")
    }
}

