import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FaHome, FaArrowLeft, FaSquare} from 'react-icons/fa'

import proefLogo from '../../../img/proef.png'
import InlineButton from '../InlineButton'

import { useAuthContext } from '../../context/AuthContext';

function Header() {
   const { user, logout } = useAuthContext()
   const navigate = useNavigate()
   const { t } = useTranslation()

   const handleBackButtonClick = () => {
      navigate(-1)
   }

   const handleLogout = async () => {
      await logout()
   }

   return (
      <>
      <header className='header'>
         <div className='header-column'>
            <Link className='header-logo' to='/'></Link>
         </div>
         <div className='header-column'>
            <div>
               <ul className='button-list-hor'>
                  <li className='button-item'>
                     <InlineButton 
                        type='link' 
                        to='/' 
                        icon={<FaHome className='icon-micro'/>} 
                        text={t("header.buttons.home")}
                     />
                  </li>
                  <li className='button-item'>
                     <InlineButton 
                        type='button'
                        handle={handleBackButtonClick}
                        icon={<FaArrowLeft className='icon-micro'/>}
                        text={t("header.buttons.back")}
                     />
                  </li>
               </ul>
               
            </div>
         </div>
         <div className='header-column'>
            <div className='header-user'>
               <div className='card-content-hor'>
                  <div>
                     <p><strong>Utilizador:</strong> {user}</p>
                  </div>
                  <ul className='button-list-hor'>
                     <li className='button-item'>
                        <InlineButton 
                           type='button'
                           handle={handleLogout}
                           icon={<FaSquare className='icon-micro'/>}
                           text='Sair'
                        />
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </header>
      </>
   )
}

export default Header