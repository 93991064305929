import axios from 'axios';
import Cookies from 'js-cookie';

import {statusCheck} from './middlewares/responseChecker';
import { env } from '../config/config';

const API = env('SERVER_API')

export const getUsers = async () => {
    try {
        const res = await axios.get(`${API}/user/`, JSON.parse(Cookies.get('authHeader')))
        
        if (statusCheck(res)) {
            return res.data        
        }

    } catch (error) {
        console.log("ERRO: Get Users")
    }
}

export const addUser = async (user) => {
    try {
        const res = await axios.post(`${API}/user`, user, JSON.parse(Cookies.get('authHeader')))
        
        if (statusCheck(res)) {
            return res.data        
        }

    } catch (error) {
        console.log("ERRO: Add user")
    }
}


export const getUsersByPrivilege = async (args) => {
    try {
        const {privilege} = args

        const res = await axios.get(`${API}/user/getUsersByPrivilege`, {
            params: {
                privilege
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data        
        }

    } catch (error) {
        console.log("ERRO: Get getUsersByPrivilege")
    }
}

export const deleteUser = async (args) => {
    try {
        const {idUser} = args
        const res = await axios.delete(`${API}/user`, {
            params: {
                idUser
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return true
       }

    } catch (error) {
        console.log(error)
        console.log("ERRO: Delete user")
    }
}


export const getLogErrors = async (userName) => {
    try {
       
        const res = await axios.get(`${API}/user/logErrors`, {
            params: {
                userName
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data        
        }

    } catch (error) {
        console.log("ERRO: getLogs")
    }
}

export const getLogTasks = async (userName) => {
    try {
        const res = await axios.get(`${API}/user/logTasks`, {
            params: {
                userName
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })

        if (statusCheck(res)) {
            return res.data        
        }

    } catch (error) {
        console.log("ERRO: getLogs")
    }
}

export const getLastLogErrors = async () => {
    try {
        const res = await axios.get(`${API}/user/lastLogErrors`, JSON.parse(Cookies.get('authHeader')))

        if (statusCheck(res)) {
            return res.data        
        }

    } catch (error) {
        console.log("ERRO: getLogsErrors")
    }
}

export const getLastLogTasks = async () => {
    try {
        const res = await axios.get(`${API}/user/lastLogTasks`, JSON.parse(Cookies.get('authHeader')))

        if (statusCheck(res)) {
            return res.data        
        }

    } catch (error) {
        console.log("ERRO: getLogsTasks")
    }
}

export const deleteLogError = async (userName, idLog) => {
    try {
        const res = await axios.delete(`${API}/user/logError`, {
            params: {
                userName,
                idLog
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
        if (statusCheck(res)) {

            if (res.data == '') {
            } else {
                return res.data
            }
       }

    } catch (error) {
        console.log("ERRO: deleteLogError")
    }
}

export const deleteLogTask = async (userName, idLog) => {
    try {
        const res = await axios.delete(`${API}/user/logTask`, {
            params: {
                userName,
                idLog
            },
            ...JSON.parse(Cookies.get('authHeader'))
        })
        if (statusCheck(res)) {

            if (res.data == '') {
            } else {
                return res.data
            }
       }

    } catch (error) {
        console.log("ERRO: deleteLogTask")
    }
}