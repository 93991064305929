import React, { useState, useEffect, useContext } from 'react'
import {  deleteLogError, deleteLogTask, getUsers, getLogErrors, getLogTasks, getLastLogErrors, getLastLogTasks } from '../../../controllers/user'
import { useAuthContext } from '../../context/AuthContext'
import { useTranslation } from 'react-i18next';
import {env} from '../../../config/config'

function LogEntry({ type, log, onDelete, userLog, isAdmin }) {
   return (
      <li className="card" key={log._id}>
         <div className='card-content-ver'>
            <ul>
               <li><p>{new Date(log.createdAt).toLocaleString()}</p></li>
               {log.user && (<li><p><strong>User:</strong> {log.user}</p></li>)}
               {type == 'task' && (<li><p><strong>Tarefa:</strong> {log.task}</p></li>)}
               {type == 'task' && (<li><p><strong>Tempo:</strong> {log.time}s</p></li>)}
               <li><p><strong>Rota:</strong> {log.route}</p></li>
               <li><p><strong>Mensagem:</strong> {log.msg}</p></li>
               {type == 'error' && (<li><p><strong>Erro:</strong> {log.error}</p></li>)}
            </ul>
            <ul className='button-list-hor'>
            {
               isAdmin && (
                  <li className='button-item'>
                     <button className="button delete" onClick={() => onDelete(userLog,log._id)}>Apagar</button>
                  </li>
               )
            }
            </ul>
         </div>
      </li>
   )
}

function Logs({type}) {
   const { t } = useTranslation()
   const { user, privileges } = useAuthContext()
   
   const [logs, setLogs] = useState([])
   const [userNames,setUserNames] = useState(undefined)
   const [selectedUser,setSelectedUser] = useState("")

   useEffect(() => {
      setSelectedUser("")
      loadLogs()
   }, [type])

   useEffect(() => {
      loadSelUserLogs()
   }, [selectedUser])

   const loadLogs = async () => {

      if (type == 'error') {
         const logsResult = await getLogErrors(user)
         if (logsResult) {
            setLogs(logsResult)
         }
      } else {
         const logsResult = await getLogTasks(user)
         if (logsResult) {
            setLogs(logsResult)
         }
      }

      if (privileges.includes(env('ADMIN'))) {
         const usersResult = await getUsers()
         if (usersResult) {
            setUserNames(usersResult.filter(u => u.username != user))
         }
         
         if (type == 'error') {
            const lastestLogs = await getLastLogErrors()
            if (lastestLogs) {
               setLogs(lastestLogs)
            }
         } else {
            const lastestLogs = await getLastLogTasks()
            if (lastestLogs) {
               setLogs(lastestLogs)
            }
         }
      }
   }

   const loadSelUserLogs = async () => {
      if (selectedUser) {
         if (type == 'error') {
            const userLogsResults = await getLogErrors(selectedUser)
            if (userLogsResults) {
               setLogs(userLogsResults)
            }
         } else {
            const userLogsResults = await getLogTasks(selectedUser)
            if (userLogsResults) {
               setLogs(userLogsResults)
            }
         }
      }
   }

   const handleDelete = async (userLog,idLog) => {
      if (type == 'error') {
         await deleteLogError(userLog, idLog)
      } else {
         await deleteLogTask(userLog, idLog)
      }

      setLogs(logs.filter((l) => l._id !== idLog))
   }
  
  
   return (
      <>
         {
            privileges.includes(env('ADMIN')) ? (
               <div className='container'>
                  <ul className='form'>
                     <li>
                        <label>Filtre pelo utilizador:</label>
                        <select 
                           className='selectBox'
                           name="user"
                           id="user"
                           onChange={(e) => setSelectedUser(e.target.value)}
                           value={selectedUser}
                        >
                           <option value="">-- Selecione um opção --</option>
                           {
                              userNames && userNames.map(u => (
                                 u.name != user ? <option value={u.name} key={u._id}>{u.name}</option> : <option value={u.name} key={u._id}>O teu user</option>
                              ))
                           }
                        </select>
                     </li>
                  </ul>
                  <div className='container'>
                  {
                     selectedUser ? (
                        <>
                           <h3>Utilizador: {selectedUser}</h3>
                           <ul className='card-list'>
                              {
                                 logs.length > 0 ? (logs.map(l => (
                                    <LogEntry
                                       type={type}
                                       key={l._id}
                                       log={l}
                                       userLog={selectedUser}
                                       onDelete={handleDelete}
                                       isAdmin={privileges.includes(env('ADMIN'))}
                                    />
                                 ))) : (
                                    <h4>Não há registos</h4>
                                 )
                              }
                           </ul>
                        </>
                     ) : (
                        <>
                           <h3>Ultimos registos</h3>
                           <ul className='card-list'>
                              {
                                 logs.length > 0 ? (logs.map(l => (
                                    <LogEntry
                                       type={type}
                                       key={l._id}
                                       log={l}
                                       userLog={selectedUser}
                                       onDelete={handleDelete}
                                       isAdmin={privileges.includes(env('ADMIN'))}
                                    />
                                 ))) : (
                                    <h4>Não possui registos</h4>
                                 )
                              }
                           </ul>
                        </>
                     )
                  }
                  </div>
               </div>
            ) : (
               <ul className='card-list'>
               {
                  logs.length > 0 ? (
                     logs.map((log) => (
                     <LogEntry
                        type={type}
                        key={log._id}
                        log={log}
                        userLog={user}
                        onDelete={handleDelete}
                        isAdmin={privileges.includes(env('ADMIN'))}
                     />
                     )
                  )) : (
                     <div className='container'>
                        <h4>Não possui registos...</h4>
                     </div>
                  )
               }
               </ul>
            )
         }
      </>
  )
}

export default Logs