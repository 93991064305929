// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin: 20px auto;
}

.inline-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-ver {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-hor {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-ver-item {
  margin: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/functional/Containers.scss"],"names":[],"mappings":"AACA;EACG,iBAAA;AAAH;;AAGA;EACG,aAAA;EACA,mBAAA;EACA,8BAAA;AAAH;;AAGA;EACG,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAAH;;AAGA;EACG,aAAA;EACA,mBAAA;EACA,8BAAA;AAAH;;AAGA;EACG,cAAA;AAAH","sourcesContent":["\n.container {\n   margin: 20px auto;\n}\n\n.inline-container {\n   display: flex;\n   align-items: center;\n   justify-content: space-between;\n}\n\n.container-ver {\n   display: flex;\n   flex-direction: column;\n   align-items: center;\n   justify-content: center;\n}\n\n.container-hor {\n   display: flex;\n   align-items: center;\n   justify-content: space-between;\n}\n\n.container-ver-item {\n   margin: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
