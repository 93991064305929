import React, { useState, useEffect, useRef } from 'react'
import Keycloak from 'keycloak-js'
import Cookies from 'js-cookie'

import {  NOS_PRIVS, VOD_PRIVS, env } from '../config/config';

import { addUser } from '../controllers/user'

const ALLOWED_PRIVS = [
   env('ADMIN'),
   ...NOS_PRIVS,
   ...VOD_PRIVS,
]

const client = new Keycloak({
   url: env('KEYCLOAK_URL'),
   realm: env('KEYCLOAK_REALM'),
   clientId: env('KEYCLOAK_CLIENT'),
})

function useAuth() {
   const isRun = useRef(false)
   const [token, setToken] = useState(null)
   const [user, setUser] = useState(null)
   const [privileges, setPrivileges] = useState(null)
   const [models, setModels] = useState(null)
   const [admins, setAdmins] = useState(null)
   const [isLoggedIn, setIsLoggedIn] = useState(false)

   useEffect(() => {
      const auth = async () => {
         if (!isRun.current) {
            isRun.current = true

            try {
               const loggedIn = await client.init({
                  onLoad: 'login-required',
               })

               console.log(client.tokenParsed)

               setIsLoggedIn(loggedIn)
               setToken(client.token)
               setUser(client.tokenParsed.name)

               const privileges = client.tokenParsed.realm_access.roles.filter(p => ALLOWED_PRIVS.includes(p))

               setPrivileges(privileges)

               var models = []
               var admins = []

               if (privileges.includes(env('ADMIN'))) {
                  models.push('nos','vodafone')
                  admins.push('nos','vodafone')
               } else {
                  if (privileges.some(p => NOS_PRIVS.includes(p))) {
                     models.push('nos')
                     if (privileges.includes(env('ADMIN_NOS'))) {
                        admins.push('nos')
                     }
                  }
                  if (privileges.some(p => VOD_PRIVS.includes(p))) {
                     models.push('vodafone')
                     if (privileges.includes(env('ADMIN_VOD'))) {
                        admins.push('vodafone')
                     }
                  }
               }

               setModels(models)
               setAdmins(admins)

               addUser(client.tokenParsed)

               Cookies.set('authHeader', JSON.stringify({ headers: { authorization: `Bearer ${client.token}` } }), { expires: 7 })
               Cookies.set('authToken', client.token, { expires: 7 })

               localStorage.removeItem('selectedModel')
               localStorage.removeItem('idOperator')
               localStorage.removeItem('selectedOperator')
            } catch (error) {
               console.log("Keycloak already initialized")
            }
         }
      }
      auth()
   }, [])

   const logout = () => client.logout()

   return { isLoggedIn, token, logout, user, privileges, models, admins }
}

export default useAuth
