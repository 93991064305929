import React, {useState} from 'react'
import { toast } from 'react-toastify'


import { upload } from '../../../../controllers/project'

import { useFileContext } from '../../../context/FileContext';
import { useParams } from 'react-router-dom';


function Form({category, cancelMethod, finishUpload}) {
   const { idOperator, idProject } = useParams()

   const [isDisable,setIsDisable] = useState(false)
   const { uploadProgress, updateUpProgress } = useFileContext()

   const [files, setFiles] = useState([])

   const handleFileChange = (e) => {
      const selectedFiles = Array.from(e.target.files)
      setFiles(selectedFiles)
   }

   const handleSubmit = async () => {
      setIsDisable(true)
      const formDataToSend = new FormData()
      
      if (files.length <= 50) {
         files.forEach(f => {
            formDataToSend.append(`file`, f, f.name.replace(/[\u00C0-\u00FF]/g, '_')) 
         })
     
         formDataToSend.append('idOperator', idOperator);
         formDataToSend.append('idProject', idProject);
         formDataToSend.append('category', category);
     
         const res = await upload(formDataToSend, (loaded) => {
            const progress = Math.round((loaded.loaded / loaded.total) * 100);
            updateUpProgress(progress)
         })

         updateUpProgress(undefined)
         finishUpload()
      } else {
         toast.info("Ultrapassou o limite de 10 ficheiros!")
      }
      setIsDisable(false)
   }

   const handleCancel = () => {
      cancelMethod()
   }

   return (
      <>
      <div className='form-container'>
         <ul className="form">
            <li>
               {
                  uploadProgress != undefined ? <span className='container'>Upload em progresso: {uploadProgress}%</span> : (
                     <>
                        <label>Ficheiros: </label>
                        <input type="file" className='inputBox' onChange={handleFileChange} multiple accept=".dxf,.xlsx" />
                     </>
                  )
               }
            </li>
         </ul>
      </div>
      {
         !uploadProgress && (
            <ul className='button-list-hor'>
               <li className='button-item'>
                  <button className="button" onClick={_ => handleSubmit()} disabled={isDisable}>Gravar</button>
               </li>
               <li className='button-item'>
                  <button className="button" onClick={_ => handleCancel()} disabled={isDisable}>Cancelar</button>
               </li>
            </ul>
         )
      }
    
      </>
   )
}

export default Form