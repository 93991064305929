import React, {useContext} from 'react'

import generalContext from '../context/GeneralContext'


function DynamicKeyValue() {
   const { 
      elements,
      setElements,
   } = useContext(generalContext)

   const handleElement = (index, type, event) => {
      const values = [...elements]
      values[index][type] = event.target.value
      setElements(values)
   }

   const handleAddElement = () => {
      const values = [...elements]
      values.push({
         key: "",
         value: ""
      })
      setElements(values)
   }

   const handleRemoveElement = (index) => {
      const values = [...elements]
      values.splice(index, 1)
      setElements(values)
   }


   return (
      <>
      <div className='grid-two'>
         <h4><strong>Chave</strong></h4>
         <h4><strong>Valor</strong></h4>
      </div>
      <ul className='form-component'>
         {
            elements?.length > 0 && elements.map((element, index) => (
               <li key={index}>
                  <div className='inline-container'>
                     <span>
                        <input 
                           className="inputBox"
                           type="text"
                           value={element.key}
                           onChange={(event) => handleElement(index, 'key', event)}
                        />
                     </span>
                     <span>
                        <input 
                           className="inputBox"
                           type="text"
                           value={element.value}
                           onChange={(event) => handleElement(index, 'value', event)}
                        />
                     </span>
                     <ul className='button-list-hor'>
                        <li className='button-item'>
                           <button className="button" onClick={() => handleRemoveElement(index)}>Remover</button>
                        </li>
                     </ul>
                  </div>
               </li>
            ))
         }
      </ul>
      <ul className='button-list-ver'>
         <li className='button-item'>
            <button className="button" onClick={handleAddElement}>Adicionar <strong>chave - valor</strong></button>
         </li>
      </ul>
      <hr></hr>
   </>
   )
}

export default DynamicKeyValue