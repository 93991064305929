import React from 'react'


function RuleSpec(props) {
    const {component, rule} = props
    const {types, installations, capacities, heights, applications, cells, spliterComponent, connections, cumulate, min, max} = rule

    return (
        <ul className='card-info'>
        {(component === 'join' || component === 'plc' || component == 'spliter') && (
            <>
                {types.length === 0 ? (
                    <li><strong>Equipamento:</strong> -- Independente --</li>
                ) : (
                    types.map(t => (
                        <li key={t}><strong>Equipamento:</strong> {t}</li>
                    ))
                )}
                {installations.length === 0 ? (
                    <li><strong>Instalação:</strong> -- Independente --</li>
                ) : (
                    installations.map(i => (
                        <li key={i}><strong>Instalação:</strong> {i}</li>
                    ))
                )}
            </>
        )}

        {(component == 'spliter') && (
            <>
                {spliterComponent == "" ? (
                    <li><strong>Tipo do Componente:</strong>{spliterComponent} -- Independente --</li>
                ) : (
                    <li><strong>Tipo do Componente:</strong> {spliterComponent}</li>
                    )
                }
            </>
        )}

        {(component == 'cable') && (
            <>
            {capacities.length === 0 ? (
                <li><strong>Capacidade:</strong> -- Independente --</li>
            ) : (
                capacities.map(c => (
                    <li key={c}><strong>Capacidade:</strong> {c}</li>
                ))
            )}
            {installations.length === 0 ? (
                    <li><strong>Instalação:</strong> -- Independente --</li>
            ) : (
                installations.map(i => (
                    <li key={i}><strong>Instalação:</strong> {i}</li>
                ))
            )}
            <li><strong>Acumulado:</strong> {cumulate ? "Sim" : "Não"}</li>
            </>
        )}

        {(component == 'post') && (
            <>
            {types.length === 0 ? (
                <li><strong>Equipamento:</strong> -- Independente --</li>
            ) : (
                types.map(t => (
                    <li key={t}><strong>Equipamento:</strong> {t}</li>
                ))
            )} 
            {heights.length === 0 ? (
                <li><strong>Altura:</strong> -- Independente --</li>
            ) : (
                heights.map(h => (
                    <li key={h}><strong>Altura:</strong> {h}</li>
                ))
            )} 
            </>
        )}

        {(component == 'trench') && (
            <>
            {installations.length === 0 ? (
                <li><strong>Instalação:</strong> -- Independente --</li>
            ) : (
                installations.map(i => (
                    <li key={i}><strong>Instalação:</strong> {i}</li>
                ))
            )}
            </>
        )}

        {(component == 'allocation') && (
            <>
            {types.length === 0 ? (
                <li><strong>Ação:</strong> -- Independente --</li>
            ) : (
                types.map(t => (
                    <li key={t}><strong>Ação:</strong> {t}</li>
                ))
            )}
            {applications.length === 0 ? (
                <li><strong>Aplicação:</strong> -- Independente --</li>
            ) : (
                applications.map(a => (
                    <li key={a}><strong>Aplicação:</strong> {a}</li>
                ))
            )}
            {cells.length === 0 ? (
                <li><strong>Célula:</strong> -- Independente --</li>
            ) : (
                cells.map(c => (
                    <li key={c}><strong>Célula:</strong> {c}</li>
                ))
            )}
            {connections.length === 0 ? (
                <li><strong>Conector:</strong> -- Independente --</li>
            ) : (
                connections.map(c => (
                    <li key={c}><strong>Conector:</strong> {c}</li>
                ))
            )}
            {min == -1 ? (
                <li><strong>Mínimo:</strong> -- Independente --</li>
            ) : (
                <li><strong>Mínimo:</strong> {min}</li>
            )}
            {max == -1 ? (
                <li><strong>Máximo:</strong> -- Independente --</li>
            ) : (
                <li><strong>Máximo:</strong> {max}</li>
            )}
            </>
        )}
        
        </ul>
    )
}

export default RuleSpec