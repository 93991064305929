import React, {useState,useEffect,useContext} from 'react'

import DynamicList from '../../../../components/DynamicList'
import GeneralContext from  '../../../../context/GeneralContext'


function Join() {
   const [loading, setLoading] = useState(true)
   const { elements: joinAttrs, setElements: setJoinAttrs} = useContext(GeneralContext)

   useEffect(() => {
      setJoinAttrs({
         layersSpl: [],
         layersFus: [],
         layersSyn: [],
         idsMap: [],
         idsSyn: [],
         spliters: [],
         blocksSpl: [],
         blocksFus: [],
         types: [],
         installations: [],
         origins: [],
         uas: [],
         secondaries: [],
         deadFibers: [],
         reservedFibers: [],
         activeFibers: [],
      })
      setLoading(false)
   },[])

   if (!loading) {
      
      const contextVariables = Object.keys(joinAttrs).reduce((acc, key) => {
         acc[key] = {
            elements: joinAttrs[key],
            setElements: (value) => setJoinAttrs((prevState) => ({ ...prevState, [key]: value }))
         }
         return acc
      }, {})
   
      return (
         <>
            {
               Object.entries(contextVariables).map(([name,context]) => (
                  <GeneralContext.Provider key={name} value={context}>
                     <DynamicList context="components" name={name}></DynamicList>
                  </GeneralContext.Provider>
               ))
            }
         </>
      )
   }
}

export default Join