import axios from 'axios'
import Cookies from 'js-cookie'

import {statusCheck} from './middlewares/responseChecker'
import { env } from '../config/config';

const API = env('SERVER_API')

export const getOperatorsByModel = async (args) => {
   try {   
      const {model} = args

      const res = await axios.get(`${API}/operator/listByModel`, {
         params: {
            model,
         },
         ...JSON.parse(Cookies.get('authHeader'))
      })
      
      if (statusCheck(res)) {
         return res.data
      }

   } catch (error) {
      console.log("ERRO: Get getOperatorsByModel")
   }
}

export const getOperator = async (args) => {
   try {
      const {idOperator} = args

      const res = await axios.get(`${API}/operator/getById`, {
         params: {
            idOperator,
         },
         ...JSON.parse(Cookies.get('authHeader'))
      })

      if (statusCheck(res)) {
         return res.data
      }

   } catch (error) {
      console.log("ERRO: Get operator")
   }
}

export const deleteOperator = async (args) => {
   try {
      const {idOperator} = args

      const res = await axios.delete(`${API}/operator/delete`, {
         params: {
            idOperator,
         },
         ...JSON.parse(Cookies.get('authHeader'))
      })

      return statusCheck(res)
      
   } catch (error) {
      console.log("ERRO: Delete operator")
   }
}

export const addOperator = async (args) => {
   try {
      const {name, model} = args

      const res = await axios.post(`${API}/operator/add`, {
         name, 
         model,
      },JSON.parse(Cookies.get('authHeader')) )

      if (statusCheck(res)) {
         return res.data
      }

   } catch (error) {
      console.log("ERRO: add operator")
   }
}

export const cloneOperator = async (args) => {
   try {
      const {idOperator, cloneName} = args

      const res = await axios.post(`${API}/operator/clone`, {
         cloneName, 
         idOperator,
      },JSON.parse(Cookies.get('authHeader')) )

      return statusCheck(res)
      
   } catch (error) {
      console.log("ERRO: Delete operator")
   }
}