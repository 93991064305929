import React, {useState,useEffect} from 'react'
import { useParams, Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getRulesByMaterial, getComponentRules} from '../../../../controllers/rule'

import ButtonList from '../../../components/ButtonList'
import RuleList from '../../../components/RuleList'
import { useTranslation } from 'react-i18next'


function Rules() {
   const {idOperator} = useParams()
   const {t} = useTranslation()
   const [rules, setRules] = useState(undefined)
   const [component, setComponent] = useState(
      localStorage.getItem('selectedRuleComponent') || undefined
   )

   useEffect(_ => {
      loadComponentRules()
   },[component])

   const loadComponentRules = async () => {
      const rulesResult = await getComponentRules({
         idOperator,
         component
      })

      if (rulesResult != null) {
         setRules(rulesResult)
      }
   }

   const handleFilterClick = (filter) => {
      if (component != filter) {
         setRules(undefined)
      }
      setComponent(filter)
      localStorage.setItem('selectedRuleComponent', filter)
   }


   return (
      <>
         <div className="grid-two">
         <div className="grid-column">
            <div className='column-title'>
               <h2>Gestão de Regras</h2>
            </div>
            <div className='button-tabulation'>
               <ButtonList
                  filters={['join','plc','cable','spliter','tube','allocation','trench','post']}
                  handleFilterClick={handleFilterClick}
                  selectedFilter={component}
               />
            </div>
         </div>
         <div className="grid-column">
            {
               component && (
                  <>
                  <div className='column-title'>
                     <h2>{t(`tabs.${component}`)}</h2>
                  </div>
                  <ul className='button-list-ver'>
                     <li className='button-item'>
                        <Link className="button" to={`/rule/${idOperator}/${component}`}>Criar Regra</Link>
                     </li>
                  </ul>  
                  <hr></hr>
                  
                  {
                     rules && (
                        <div>
                           {
                              rules?.length > 0 ? (
                                 <RuleList 
                                    idOperator={idOperator}
                                    component={component}
                                    rules={rules}
                                 />
                              ) : (
                                 <h3>Não possui regras...</h3>
                              )
                           }
                        </div>
                     )
                  }
                  </>
               )
            }
         </div>
      </div>
      </>
   )
}

export default Rules