// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/logoProefDynamic.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes play {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -57420px;
  }
}
.loading {
  background-image: initial;
  background-color: rgb(0, 111, 255);
  background: rgb(0, 111, 255);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999999;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  text-align: center;
}

.loading-icon {
  display: block;
  margin: auto;
  z-index: 1;
  width: 220px;
  height: 90px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) left center;
  animation: play 8.11s steps(261) infinite;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/Loading.scss"],"names":[],"mappings":"AACA;EACG;IAAK,wBAAA;EACN;EAAC;IAAO,6BAAA;EAGR;AACF;AAAA;EACG,yBAAA;EACA,kCAAA;EACA,4BAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,sBAAA;EACA,kBAAA;AAEH;;AAEA;EACG,cAAA;EACA,YAAA;EACA,UAAA;EACA,YAAA;EACA,YAAA;EACA,+DAAA;EACA,yCAAA;AACH","sourcesContent":["\n@keyframes play {\n   0% { background-position: 0 0; }\n   100% { background-position: -57420px }\n}\n\n\n.loading {\n   background-image: initial;\n   background-color: rgba(0, 111, 255, 1);\n   background: rgba(0, 111, 255, 1);\n   position: fixed;\n   top: 0;\n   left: 0;\n   bottom: 0;\n   right: 0;\n   z-index: 9999999;\n   display: flex;\n   align-items: center;\n   -webkit-align-items: center;\n   -moz-box-align: center;\n   text-align: center;\n}\n\n\n.loading-icon {\n   display: block;\n   margin: auto;\n   z-index: 1;\n   width: 220px;\n   height: 90px;\n   background: url(../../../public/logoProefDynamic.png) left center;\n   animation: play 8.11s steps(261) infinite;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
