import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import {
   downloadSyn,
   executeSyn,
   getSynResults,
} from '../../../../controllers/synGen'
import { downloadProjectFile } from '../../../../controllers/project'
import { useParams } from 'react-router-dom'
import { useSynContext } from '../../../context/SynContext'
import ButtonTabs from '../../../components/ButtonTabs'

function SynGen() {
   const { idOperator, idProject } = useParams()
   const { syn, updateSyn } = useSynContext()
   const [phase, setPhase] = useState(localStorage.getItem('phase') || 'zero')
   const [results, setResults] = useState({
      zero: [],
      one: [],
   })
   const [displayedImage, setDisplayedImage] = useState(null)
   const [progress, setProgress] = useState(null)

   useEffect(() => {
      if (!syn) {
         setResults({
            zero: [],
            one: [],
         })
         loadResults()
      }
   }, [phase, syn])

   const loadResults = async () => {
      const synResults = await getSynResults({
         idOperator,
         idProject,
         phase,
      })

      if (synResults && synResults.length > 0) {
         setResults((results) => ({ ...results, [phase]: synResults }))
      }
   }

   const handleSelectPhase = (phase) => {
      setPhase(phase)
      localStorage.setItem('phase', phase)
   }

   const handleExecuteSyn = async (event) => {
      updateSyn(true)

      const results = await executeSyn({
         idOperator,
         idProject,
         phase,
      })

      updateSyn(false)
   }

   const downloadFile = async (file) => {
      await downloadSyn(
         {
            idOperator,
            idProject,
            phase,
            file,
         },
         (loaded) => {
            const progress = Math.round((loaded.loaded / loaded.total) * 100)
            setProgress(progress)
         }
      )

      setProgress(undefined)
   }

   const showSynopticPNG = async (file) => {
      const synopticImage = await downloadProjectFile({
         idOperator,
         idProject,
         file: file.replace(/\.dxf/g, '.png'),
         downloadType: 2,
      })

      if (synopticImage) {
         setDisplayedImage(synopticImage)
      }
   }

   return (
      <>
         <div className="grid-two">
            <div className="grid-column">
               <div className="column-title">
                  <h2>Gerador de sinóticos</h2>
               </div>
               <div className="button-tabulation">
                  <ButtonTabs
                     filters={['zero', 'one']}
                     handleFilterClick={handleSelectPhase}
                     selectedFilter={phase}
                  />
               </div>
               <hr></hr>
               <ul className="button-list-ver">
                  <li className="button-item">
                     <button
                        className="button"
                        onClick={(_) => handleExecuteSyn()}
                        disabled={syn}
                     >
                        Executar gerador
                     </button>
                  </li>
               </ul>
            </div>

            <div className="grid-column">
               <h3>Resultados</h3>

               <ul className="card-list">
                  {results[phase].length > 0 ? (results[phase].map((s) => (
                     <li className="card" key={s}>
                        <div className="card-content-hor">
                           <ul>
                              <li>
                                 <p>
                                    Arquivo: <strong>{s}</strong>
                                 </p>
                              </li>
                           </ul>

                           <ul className="button-list-hor">
                              <li className="button-item">
                                 <button
                                    className="button"
                                    onClick={(_) => downloadFile(s)}
                                    disabled={progress}
                                 >
                                    Transferir
                                 </button>
                              </li>
                           </ul>
                        </div>
                     </li>
                  ))) : (
                     <h3>Ainda sem sinóticos gerados...</h3>
                  )}
               </ul>
            </div>
         </div>
      </>
   )
}

export default SynGen
