import React from 'react'
import * as ReactDOM from 'react-dom/client'

import App from './app'

import './styles/index.scss'

import { LockingProvider } from './components/context/LockingContext'
import { FileProvider } from './components/context/FileContext'
import { BomProvider } from './components/context/BomContext'
import { ValidatorProvider } from './components/context/ValidatorContext'

import global_en from './languages/en/global.json'
import global_pt from './languages/pt/global.json'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import { SynProvider } from './components/context/SynContext'

i18n.use(initReactI18next).init({
   resources: {
      en: { translation: global_en },
      pt: { translation: global_pt },
   },
   lng: 'pt',
   interpolation: { escapeValue: false },
})

const container = document.getElementById('root')

// Create a root.
const root = ReactDOM.createRoot(container)

root.render(
   <I18nextProvider i18n={i18n}>
      <LockingProvider>
         <FileProvider>
            <ValidatorProvider>
               <BomProvider>
                  <SynProvider>
                     <App></App>
                  </SynProvider>
               </BomProvider>
            </ValidatorProvider>
         </FileProvider>
      </LockingProvider>
   </I18nextProvider>
)
