// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
  font-size: 24px;
  padding: 0 0 5px 0;
}

.icon-micro {
  font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/styles/functional/Icons.scss"],"names":[],"mappings":"AAAA;EACG,eAAA;EACA,kBAAA;AACH;;AAEA;EACG,eAAA;AACH","sourcesContent":[".icon {\n   font-size: 24px;\n   padding: 0 0 5px 0;\n}\n\n.icon-micro {\n   font-size: 15px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
