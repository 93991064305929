import React from 'react'

function NotFound() {
    return (
        <div className='contentTitle'>
            <h2>Não encontrado 👻</h2>
        </div>
    )
}

export default NotFound