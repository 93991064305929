// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-list {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.card {
  background-color: rgba(0, 111, 255, 0.5);
  padding: 10px;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
}

.grey {
  background-color: lightgrey;
}

.card-content-ver {
  display: flex;
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
}

.card-content-hor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  box-sizing: border-box;
}

.card-info {
  text-align: left;
  justify-content: center;
  align-items: center;
}

.card-info-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-info-item p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/styles/functional/Cards.scss"],"names":[],"mappings":"AACA;EACG,aAAA;EACA,mBAAA;EACA,sBAAA;AAAH;;AAGA;EACG,wCAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,sBAAA;AAAH;;AAGA;EACG,2BAAA;AAAH;;AAGA;EACG,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,sBAAA;AAAH;;AAGA;EACG,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;AAAH;;AAGA;EACG,gBAAA;EACA,uBAAA;EACA,mBAAA;AAAH;;AAGA;EACG,mBAAA;EACA,gBAAA;EACA,uBAAA;AAAH;;AAGA;EACG,mBAAA;EACA,gBAAA;EACA,uBAAA;AAAH","sourcesContent":["//Card\n.card-list {\n   display: flex;\n   align-items: center;\n   flex-direction: column;\n}\n\n.card {\n   background-color: rgba(0,111,255,0.5);\n   padding: 10px;\n   border-radius: 20px;\n   width: 100%;\n   box-sizing: border-box;\n}\n\n.grey {\n   background-color: lightgrey;\n}\n\n.card-content-ver {\n   display: flex;\n   flex-direction: column;\n   text-align: left;\n   box-sizing: border-box;\n}\n\n.card-content-hor {\n   display: flex;\n   justify-content: space-between;\n   align-items: center;\n   text-align: left;\n   box-sizing: border-box;\n}\n\n.card-info {\n   text-align: left;\n   justify-content: center;\n   align-items: center;\n}\n\n.card-info-item {\n   white-space: nowrap;\n   overflow: hidden;\n   text-overflow: ellipsis;\n}\n\n.card-info-item p {\n   white-space: nowrap;\n   overflow: hidden;\n   text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
