import React, {useState} from 'react'

import ButtonList from '../../../components/ButtonList'
import { useTranslation } from 'react-i18next'

import { useParams } from 'react-router-dom'
import Component from './Component'


function Components() {
   const {idOperator} = useParams()

   const { t } = useTranslation()
   const [component, setComponent] = useState(
      localStorage.getItem('selectedComponent') || undefined
   )

   const handleFilterClick = (filter) => {
      setComponent(filter)
      localStorage.setItem('selectedComponent', filter)
   }

   return (
      <>
      <div className="grid-two">
         <div className="grid-column">
            <div className='column-title'>
               <h2>Gestão de Componentes</h2>
            </div>
            <div className='button-tabulation'>
               <ButtonList
                  filters={['join','plc','cable','area','tube','habitation','trench','post','table']}
                  handleFilterClick={handleFilterClick}
                  selectedFilter={component}
               />
            </div>

         </div>
         <div className="grid-column">
            <div className='column-title'>
               <h2>{t(`tabs.${component}`)}</h2>
            </div>
            <Component idOperator={idOperator} component={component}/>
         </div>
      </div>
      </>
   )
}

export default Components