import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getTemplates } from '../../../../controllers/template'
import { executeBom, executePreBom, getPreBomInfo, checkPreBom } from '../../../../controllers/bomGen'
import LockingContext from '../../../context/LockingContext'
import ButtonTabs from '../../../components/ButtonTabs'

import { useBomContext } from '../../../context/BomContext';


const renderNestedObject = (info) => {
   return (
      <ul className='card-list'>
         {Object.entries(info).map(([key, value]) => (
               <li className="card" key={key}>
                  <strong>{key}</strong>
                  {typeof value === 'object' ? renderNestedObject(value) : `: ${value}`}
               </li>
         ))}
      </ul>
   )
}

const showPreBomInfo = (component,info) => {
   return (
      <>
      {renderNestedObject(info)}
      </>
   )
}


function BomGen() {
   const { idOperator, idProject } = useParams()
   const {lockOperator} = useContext(LockingContext)
   const { preBom, bom, updatePreBom, updateBom } = useBomContext()

   const navigate = useNavigate()

   const [templates,setTemplates] = useState([])
  
   const [template,setTemplate] = useState('')
   const [network,setNetwork] = useState('independent')
   const [bomType,setBomType] = useState('total')

   const [preBomGenerated,setpreBomGenerated] = useState(false) 

   const [selectedComponent,setSelectedComponent] = useState(null)
   const [preBomInfo,setPreBomInfo] = useState(null)

   useEffect(() => {
      if (!preBom) {
         loadPreBom()
         loadTemplates()
      }
   },[preBom])

   useEffect(() => {
      if (template && network && bomType) {
         updateBom(false)
      } else {
         updateBom(true)
      }
   }, [template,network,bomType])

   const loadTemplates = async () => {
      const templatesResult = await getTemplates({ 
         idOperator,
      })

      if (templatesResult) {
         for (const k in templatesResult) {
            if (templatesResult[k].isDefault) {
                  setTemplate(templatesResult[k].date)
                  break
            }
         }
         setTemplates(templatesResult)
      }
   }

   const loadPreBom = async () => {
      const results = await checkPreBom({
         idOperator,
         idProject
      })

      if (results) {
         setpreBomGenerated(true)
      } else {
         setpreBomGenerated(false)
      }
   }

   const handleExecutePreBom = async (event) => {
      updatePreBom(true)

      const results = await executePreBom({
         idOperator,
         idProject
      })

      updatePreBom(false)

      if (results) {
         setpreBomGenerated(true)
         toast.info("PreBom gerado!")
      } 
   }


   const handleExecuteBom = async () => {
      if (!preBom) {
         updateBom(true)
         
         const results = await executeBom({
            idOperator,
            idProject,
            templateDate: template,
            bomType,
            network
         })

         if (results) {
            toast.info("BOM gerado!")
         }

         updateBom(false)
      }
   }

   const handleGetPreBomInfo = async (component) => {
      if (preBomGenerated) {
         setSelectedComponent(component)
         setPreBomInfo(null)

         const preBomInfo = await getPreBomInfo({
            idOperator,
            idProject,
            component
         })

         if (preBomInfo) {
            if (Object.keys(preBomInfo) == 0) {
               setPreBomInfo(undefined)
            } else {
               setPreBomInfo(preBomInfo)
            }
         } else {
            setPreBomInfo(undefined)
         }
      }
   }

   const checkAccess = async (idOperator,route) => {
      if (await lockOperator(idOperator)) {
         navigate(route)
      }
  }

   return (
      <>
      <div className='content'>
         <div className={(preBomGenerated) ? 'grid-two' : ''}>
            <div className={(preBomGenerated) ? 'grid-column' : ''}>
            
            {(preBomGenerated) ? (
               <>
               <div className='column-title'>
                  <h2>BoM</h2>
               </div>
               <div className='form-container'>
                  <ul className='form'>
                     <li>
                        <label htmlFor="template">Template:</label>
                        <div className='inline-container'>
                           <select 
                              className='selectBox'
                              value={template}
                              name="template"
                              id="template"
                              disabled={preBom}
                              onChange={(e) => {setTemplate(e.target.value)}}
                           >
                              <option value="">-- Selecione um opção --</option>
                              {templates.map(t => (
                                    <option value={t.date} key={t.date}>{t.date}</option>
                              ))}
                           </select>
                           {(!template) && (
                              <ul className='button-list-hor'>
                                 <li className='button-item'>
                                    <button className='button' onClick={() => checkAccess(idOperator,`/template/${idOperator}/addTemplate`)}>Adicionar</button>
                                 </li>
                              </ul>
                           )}
                        </div>
                     </li>
                     <li>
                        <label>Tipo de BoM:</label>
                        <div className='inline-container'>
                           <select 
                              className='selectBox'
                              value={bomType}
                              disabled={preBom}
                              onChange={(e) => setBomType(e.target.value)}
                           >
                              <option value="total">Total</option>
                              <option value="notConst">Não Construção</option>
                              <option value="const">Construção</option>
                           </select>
                        </div>
                     </li>
                     <li>
                        <label>Tipo de network:</label>
                        <select 
                           className='selectBox'
                           value={network}
                           disabled={preBom}
                           onChange={(e) => setNetwork(e.target.value)}
                        >
                           <option value="independent">Independente</option>
                           <option value="primary">Primária</option>
                           <option value="secondary">Secundária</option>
                        </select>
                     </li>
                  </ul>
               </div>
               <div className='container'>
                  <button 
                     className="button"
                     onClick={_ => handleExecutePreBom()}
                     disabled={preBom}>Re-gerar Pré-BoM</button>
               </div>
               <div className='container'>
                  <button 
                     className="button"
                     onClick={handleExecuteBom}
                     disabled={bom || preBom}>Gerar BoM</button>
               </div>
               
               </>
            ) : (
               <button 
                  className="button"
                  onClick={handleExecutePreBom}
                  disabled={preBom}>Gerar Pré-BoM</button>
            )}
            </div>
            {
               (preBomGenerated) && (
                  <div className='grid-column'>
                     <h3>Pré-Bom</h3>
                     <ButtonTabs
                        filters={['join', 'plc', 'spliter', 'cable', 'post', 'tube', 'trench', 'allocation']}
                        handleFilterClick={handleGetPreBomInfo}
                        selectedFilter={selectedComponent}
                     />
                     <hr></hr>
                     {
                        (preBomGenerated && selectedComponent) && (
                           preBomInfo ? (
                              <div className='content'>
                                 {showPreBomInfo(selectedComponent,preBomInfo)}    
                              </div>
                           ) : (
                              <h2>Nada foi encontrado...</h2>
                           ) 
                        )
                     }
                  </div>
               )
            }
         </div>
      </div>
      </>
   )
}

export default BomGen