import React from "react"


function Footer() {

    return (
        <div className='footer'>
            <p>Proef FTTx Next</p>
        </div>
    )
}

export default Footer