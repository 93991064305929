// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-strait {
  width: 60%;
}

.form {
  width: 100%;
}

.form li, .form-strait li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form select {
  width: 170px;
}

.logs {
  padding: 30px 0;
}

.red {
  background: crimson;
}

.inputBox {
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 15px;
  background: white;
  font-size: 15px;
}

.selectBox {
  border-radius: 10px;
  padding: 7px 0;
  max-width: 100%;
  text-align: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/functional/Forms.scss"],"names":[],"mappings":"AAGA;EACG,cAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAFH;;AAKA;EACG,UAAA;AAFH;;AAKA;EACG,WAAA;AAFH;;AAKA;EACG,aAAA;EACA,8BAAA;EACA,mBAAA;AAFH;;AAKA;EACG,YAAA;AAFH;;AAMA;EACG,eAAA;AAHH;;AAMA;EACG,mBAAA;AAHH;;AAMA;EACG,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;AAHH;;AAOA;EACG,mBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;AAJH","sourcesContent":["\n\n//Forms\n.form-container {\n   margin: 15px 0;\n   display: flex;\n   flex-direction: column;\n   align-items: center;\n   justify-content: center;\n}\n\n.form-strait {\n   width: 60%;\n}\n\n.form {\n   width: 100%;\n}\n\n.form li, .form-strait li {\n   display: flex;\n   justify-content: space-between;\n   align-items: center;\n}\n\n.form select {\n   width: 170px;\n}\n\n\n.logs {\n   padding: 30px 0;\n}\n\n.red {\n   background: crimson;\n}\n\n.inputBox {\n   padding: 10px;\n   border: 1px solid lightgray;\n   border-radius: 15px;\n   background: white;\n   font-size: 15px;\n}\n\n\n.selectBox {\n   border-radius: 10px;\n   padding: 7px 0;\n   max-width: 100%;\n   text-align: center;\n   cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
