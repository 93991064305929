import React, { useCallback, useEffect, useState } from 'react'
import Join from './components/Join'
import GeneralContext from '../../../context/GeneralContext'
import { getComponent, saveComponent } from '../../../../controllers/component'
import { toast } from 'react-toastify'
import Plc from './components/Plc'
import Cable from './components/Cable'
import Area from './components/Area'
import Tube from './components/Tube'
import Trench from './components/Trench'
import Habitation from './components/Habitation'
import Table from './components/Table'
import Post from './components/Post'

function Component({idOperator, component}) {

   const [attrs,setAttrs] = useState(undefined)

   const contextState = {
      elements: attrs,
      setElements: setAttrs
   }

   useEffect(_ => {
      loadComponent()
   }, [idOperator,component])

   const loadComponent = useCallback(async () => {
      const result = await getComponent({ 
         idOperator,
         type: component
      })
      if (result != undefined) {
         setAttrs(result)
      }
   },[idOperator, component])


   const handleSubmit = async () => {
      const res = await saveComponent({ 
         idOperator, 
         type: component, 
         component: attrs 
      })
      if (res) {
         toast.info("Guardado")
      }
   }
   
   return (
      <>
      <div className='content'>
         <div className='form-container'>
            <ul className='form'>
               <GeneralContext.Provider key={component} value={contextState}>
                  {
                     component == 'join' ? <Join/> :
                     component == 'plc' ? <Plc/> :
                     component == 'cable' ? <Cable/> :
                     component == 'area' ? <Area/> :
                     component == 'tube' ? <Tube/> :
                     component == 'trench' ? <Trench/> :
                     component == 'habitation' ? <Habitation/> :
                     component == 'table' ? <Table/> :
                     component == 'post' ? <Post/> :
                     <h3>Componente inexistente</h3>
                  }
               </GeneralContext.Provider>
            </ul>
         </div>
         <ul className='button-list-ver'>
            <li className='button-item'><button className="button" onClick={() => handleSubmit()}>Gravar</button></li>
         </ul>
      </div>
      </>
   )
}

export default Component