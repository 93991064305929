import React, { useState, useEffect } from 'react'

import ButtonTabs from '../../../components/ButtonTabs'

import { downloadProjectFile } from '../../../../controllers/project'
import {
   executeValidator,
   getAnomalies,
} from '../../../../controllers/validator'
import { toast } from 'react-toastify'

import { useValidatorContext } from '../../../context/ValidatorContext'
import { useParams } from 'react-router-dom'

const NestedObjectComponent = ({ results }) => {
   return (
      <ul>
         {results.map((object, index) => {
            return Object.keys(object).length > 0 ? (
               <li className="container" key={index}>
                  <NestedObjectLevel object={object} />
               </li>
            ) : null
         })}
      </ul>
   )
}

const NestedObjectLevel = ({ object }) => {
   return (
      <ul>
         {Object.entries(object).map(([id1, object2]) => (
            <>
               {typeof object2 === 'object' ? (
                  <li className="card" key={id1}>
                     <p>
                        <strong>{id1}</strong>
                     </p>
                     <NestedObjectLevel object={object2} />
                  </li>
               ) : (
                  <li>
                     <p>
                        <strong>{id1}: </strong> {object2}
                     </p>
                  </li>
               )}
            </>
         ))}
      </ul>
   )
}

function Validator() {
   const { idOperator, idProject } = useParams()

   const { validator, updateValidator } = useValidatorContext()
   const [phase, setPhase] = useState(localStorage.getItem('phase') || 'zero')
   const [results, setResults] = useState({
      zero: [],
      one: [],
   })
   const [downloadInProgress, setDownloadInProgress] = useState(false)

   useEffect(
      (_) => {
         if (!validator) {
            setResults({
               zero: [],
               one: [],
            })
            loadResults()
         }
      },
      [phase, validator]
   )

   const loadResults = async () => {
      const anomaliesResults = await getAnomalies({
         idOperator,
         idProject,
         phase,
      })

      if (anomaliesResults) {
         setResults((results) => ({ ...results, [phase]: anomaliesResults }))
      }
   }

   const downloadFile = async () => {
      setDownloadInProgress(true)

      await downloadProjectFile({
         idOperator,
         idProject,
         file: `anomalies_${phase}.xlsx`,
         downloadType: 3,
      })

      setDownloadInProgress(false)
   }

   const handleSelectPhase = (phase) => {
      setPhase(phase)
      localStorage.setItem('phase', phase)
   }

   const handleValidator = async () => {
      updateValidator(true)

      const anomaliesResults = await executeValidator({
         idOperator,
         idProject,
         phase,
      })

      if (anomaliesResults) {
         setResults((prevState) => ({
            ...prevState,
            [phase]: anomaliesResults,
         }))
         toast.info('Validação terminada!')
      }
      updateValidator(false)
   }

   return (
      <>
         <div className="grid-two">
            <div className="grid-column">
               <div className="column-title">
                  <h2>Validação</h2>
               </div>
               <div className="button-tabulation">
                  <ButtonTabs
                     filters={['zero', 'one']}
                     handleFilterClick={handleSelectPhase}
                     selectedFilter={phase}
                  />
               </div>
               <hr></hr>
               <ul className="button-list-ver">
                  <li className="button-item">
                     <button
                        className="button"
                        onClick={(_) => handleValidator()}
                        disabled={validator}
                     >
                        Executar validador
                     </button>
                  </li>
                  {results[phase] && results[phase].length > 0 && (
                     <>
                        <hr></hr>
                        <li className="button-item">
                           <button
                              className="button"
                              onClick={(_) => downloadFile()}
                              disabled={downloadInProgress}
                           >
                              Transferir excel
                           </button>
                        </li>
                     </>
                  )}
               </ul>
            </div>
            <div className="grid-column">
               <div className="column-title">
                  <h2>Anomalias</h2>
               </div>
               {results[phase] && results[phase].length > 0 ? (
                  <NestedObjectComponent results={results[phase]} />
               ) : (
                  <h3>Não existem anomalias...</h3>
               )}
            </div>
         </div>
      </>
   )
}

export default Validator
