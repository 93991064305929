import React, {useState, useEffect} from 'react'

import ButtonList from '../../../components/ButtonList'
import { useTranslation } from 'react-i18next'
import {getConfigNames, getConfig, addConfig, addConfigName, deleteConfig} from '../../../../controllers/validator'
import { toast } from 'react-toastify'
import GeneralContext from  '../../../context/GeneralContext'
import DynamicKeyValue from '../../../components/DynamicKeyValue'
import { useParams } from 'react-router-dom'


function Configs() {
   const {idOperator} = useParams()
   const [configName,setConfigName] = useState('')
   const [showAddConfigForm,setShowAddConfigForm] = useState(false)
   const [configNames,setConfigNames] = useState([])
   const [config,setConfig] = useState([])
   
   const [configSelected, setConfigSelected] = useState(undefined)

   const contextVariablesConfig = {
      elements: config,
      setElements: setConfig
   }

   const handleFilterClick = (filter) => {
      setConfigSelected(filter)
      localStorage.setItem('configSelected', filter)
   }

   const handleAddConfigName = async () => {
      const res = await addConfigName({
         idOperator,
         configName
      })

      if (res) {
         setShowAddConfigForm(false)
         setConfigName('')
         loadConfigNames()
      }
   }

   const handleAddConfig = async () => {
      const res = await addConfig({
         idOperator,
         configName: configSelected,
         config: config.filter(c => (c.key != '' && c.value != ''))
      })

      if (res) {
         toast.info("Guardado")
      }
   }

   const handleDeleteConfig = async () => {
      const res = await deleteConfig({idOperator,configName: configSelected})

      if (res) {
         loadConfigNames()
         setConfigSelected(undefined)
         localStorage.removeItem('configSelected')
      }
   }

   useEffect(_ => {
      loadConfigNames()
   },[])

   useEffect(_ => {
      loadConfig()
   },[configSelected])

   const loadConfigNames = async () => {
      const res = await getConfigNames({idOperator})
      
      if (res) {
         setConfigNames(res)
         const sel = localStorage.getItem('configSelected')

         if (res.includes(sel)) {
            setConfigSelected(sel)
         }

      }
   }

   const loadConfig = async () => {
      const res = await getConfig({
         idOperator,
         configName: configSelected
      })
      
      if (res) {
         setConfig(res)
      }
   }

   return (
      <>
      <div className="grid-two">
         <div className="grid-column">
            <div className='column-title'>
               <h2>Configurações</h2>
            </div>
            <div className='button-tabulation'>
               {
                  showAddConfigForm ? (
                     <>
                     <div className='form-container'>
                        <ul className='form'>
                           <li>
                              <label>Nome:</label>
                              <input className="inputBox" type="text" onChange={(e) => setConfigName(e.target.value)}/>
                           </li>
                        </ul>
                     </div>
                     <ul className='button-list-hor'>
                        <li className='button-item'>
                           <button className="button" onClick={_ => handleAddConfigName()}>Gravar</button>
                        </li>
                        <li className='button-item'>
                           <button className="button" onClick={_ => setShowAddConfigForm(false)}>Cancelar</button>
                        </li>
                     </ul>
                     </>
                  ) : (
                     <button className='button' onClick={_ => setShowAddConfigForm(true)}>Adicionar Config</button>
                  )
               }
               
               <hr></hr>
               <ButtonList
                  filters={configNames}
                  handleFilterClick={handleFilterClick}
                  selectedFilter={configSelected}
               />
            </div>

         </div>
         <div className="grid-column">
            {
               configSelected && (
                  <>
                  <div className='column-title'>
                     <h2>{configSelected}</h2>
                  </div>
                  <GeneralContext.Provider value={contextVariablesConfig}>
                     <DynamicKeyValue/>
                  </GeneralContext.Provider>
                  <ul className='button-list-hor'>
                     <li className='button-item'>
                        <button className='button' onClick={_ => handleAddConfig()}>Gravar</button>
                     </li>
                     <li className='button-item'>
                        <button className='button delete' onClick={_ => handleDeleteConfig()}>Apagar</button>
                     </li>
                  </ul>
                  </>
               )
            }
         </div>
      </div>
      </>
   )
}

export default Configs