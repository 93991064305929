// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/logoProefDynamic.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #006FFF;
  padding: 0px 30px;
  position: relative;
}

.header-logo {
  display: block;
  margin: auto;
  z-index: 1;
  width: 216px;
  height: 80px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) left center;
  animation: play 8.11s steps(261);
  scale: 0.5;
}

.header-column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header h1 {
  color: white;
  text-align: center;
  font-weight: 600;
}

.header-image img {
  width: 90px;
}

.main-buttons {
  display: block;
  text-align: center;
  margin: 20px auto 0 auto;
}

.header-user {
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header-user p {
  padding: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui/Header.scss"],"names":[],"mappings":"AAAA;EACG,aAAA;EACA,kCAAA;EACA,yBAAA;EACA,iBAAA;EACA,kBAAA;AACH;;AAEA;EACG,cAAA;EACA,YAAA;EACA,UAAA;EACA,YAAA;EACA,YAAA;EACA,+DAAA;EACA,gCAAA;EACA,UAAA;AACH;;AAEA;EACG,aAAA;EACA,mBAAA;EACA,uBAAA;AACH;;AAEA;EACG,YAAA;EACA,kBAAA;EACA,gBAAA;AACH;;AAEA;EACG,WAAA;AACH;;AAEA;EACG,cAAA;EACA,kBAAA;EACA,wBAAA;AACH;;AAEA;EACG,YAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;AACH;;AAEA;EACG,cAAA;AACH","sourcesContent":[".header {\n   display: grid;\n   grid-template-columns: 1fr 1fr 1fr;\n   background-color: #006FFF;\n   padding: 0px 30px;\n   position: relative;\n}\n\n.header-logo {\n   display: block;\n   margin: auto;\n   z-index: 1;\n   width: 216px;\n   height: 80px;\n   background: url(../../../public/logoProefDynamic.png) left center;\n   animation: play 8.11s steps(261);\n   scale: 0.5;\n}\n\n.header-column {\n   display: flex;\n   align-items: center;\n   justify-content: center;\n}\n\n.header h1 {\n   color: white;\n   text-align: center;\n   font-weight: 600;\n}\n\n.header-image img {\n   width: 90px;\n}\n\n.main-buttons {\n   display: block;\n   text-align: center;\n   margin: 20px auto 0 auto;\n}\n\n.header-user {\n   color: white;\n   font-size: 16px;\n   display: flex;\n   justify-content: center;\n   flex-direction: column;\n}\n\n.header-user p {\n   padding: 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
