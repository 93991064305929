import React, { useContext, useEffect, useState } from 'react'
import DynamicList from '../../../components/DynamicList'
import GeneralContext from  '../../../context/GeneralContext'

function Trench() {
   const [loading,setLoading] = useState(true)
   const { elements: rule, setElements: setRule} = useContext(GeneralContext)

   useEffect(() => {
      if (!rule) {
         setRule({
            installations: [],
         })
      }
      setLoading(false)
   }, [])


   if (!loading) {

      const handleChange = (value, field) => {
         setRule(prevState => ({ ...prevState, [field]: value }))
      }

      return (
         <li>
            <label htmlFor="installations"><strong>Instalação</strong>:</label>
            <select className='inputBox' name="installations" id="installations" value={rule.installations[0]} onChange={(e) => {handleChange([e.target.value],'installations')}}>
               <option value="">-- Selecione um opção --</option>
               <option value="asfalto">Asfalto</option>
               <option value="passeio">Passeio</option>
               <option value="terra">Terra</option>
            </select>
         </li>
      )
   }
}

export default Trench