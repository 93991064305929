import React, { createContext, useState, useContext } from 'react';

import { lock, unlock } from '../../controllers/locker';

const LockingContext = createContext();

export const LockingProvider = ({ children }) => {
    const [operatorLocked, setOperatorLocked] = useState(false);

    const lockOperator = async (idOperadora) => {

        const obj = await lock(idOperadora)

        if (obj) {
            setOperatorLocked(true)
            return true
        } else {
            return false
        }
    };

    const unlockOperator = async () => {

        const obj = await unlock()

        if (obj) {
            setOperatorLocked(false)
            return true
        } else {
            return false
        }
    } 

    return (
        <LockingContext.Provider value={{operatorLocked, lockOperator, unlockOperator}}>
            {children}
        </LockingContext.Provider>
    );
};


export default LockingContext
