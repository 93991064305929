import React from 'react'
import { Link, useParams } from 'react-router-dom'

function AddRule() {
    const {idOperator} = useParams()

    return (
        <>
        <div className='contentTitle'>
            <h2>Criar Regra</h2>
        </div>
        <div className='content'>
            <ul className='button-list-hor'>
                <li className='button-item'>
                    <Link className="button" to={`/rule/${idOperator}/join`}>Juntas</Link>
                </li>
                <li className='button-item'>
                    <Link className="button" to={`/rule/${idOperator}/plc`}>PLC's</Link>
                </li>
                <li className='button-item'>
                    <Link className="button" to={`/rule/${idOperator}/spliter`}>Spliters</Link>
                </li>
                <li className='button-item'>
                    <Link className="button" to={`/rule/${idOperator}/cable`}>Cabos</Link>
                </li>
                <li className='button-item'>
                    <Link className="button" to={`/rule/${idOperator}/post`}>Postes</Link>
                </li>
                <li className='button-item'>
                    <Link className="button" to={`/rule/${idOperator}/tube`}>Tubos de Subida</Link>
                </li>
                <li className='button-item'>
                    <Link className="button" to={`/rule/${idOperator}/trench`}>Valas</Link>
                </li>
                <li className='button-item'>
                    <Link className="button" to={`/rule/${idOperator}/allocation`}>Alocações</Link>
                </li>
            </ul>
        </div>
        </>
    )
}

export default AddRule