import React, { useState, useEffect, useContext } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import LockingContext from '../../context/LockingContext'
import ButtonList from '../../components/ButtonList'

import { addOperator, getOperatorsByModel } from '../../../controllers/operator'
import { useAuthContext } from '../../context/AuthContext'

function GestOperators() {
   const {privileges,models} = useAuthContext()
   const {lockOperator} = useContext(LockingContext)
   const navigate = useNavigate()

   const [model, setModel] = useState(
      localStorage.getItem('selectedModel') || undefined
   )

   const [operators, setOperators] = useState([])
   const [searchTerm, setSearchTerm] = useState('')

   const [operatorName, setOperatorName] = useState("") 
   const [showAddOperatorForm, setShowAddOperatorForm] = useState(false) 

   useEffect(_ => {
      loadOperators()
   }, [model])

   const loadOperators = async () => {
      if (model) {
         const operatorsResult = await getOperatorsByModel({
            model
         })
         if (operatorsResult) {
            setOperators(operatorsResult)
         }
      }
   }

   const checkAccess = async (idOperadora,route) => {
      //if (await lockOperator(idOperadora)) {
         navigate(route)
      //} 
   }

   const handleSearch = (event) => {
      setSearchTerm(event.target.value)
   }

   const filteredOperators = operators ? operators.filter(m => m.name.toLowerCase().includes(searchTerm.toLowerCase())) : []

   const handleSelectModel = (filter) => {
      setModel(filter)
      localStorage.setItem('selectedModel', filter)
      localStorage.removeItem('selectedOperator')
      localStorage.removeItem('idOperator')
   }

   const handleSubmeter = async () => {
      if (operatorName != "") {
         const res = await addOperator({
            name: operatorName,
            model,
        })

         if (res) {
            setShowAddOperatorForm(false)
            setOperators([...operators,{name: operatorName, _id: res._id}])
         }
      } else {
            toast.error("Formulario incompleto!")
      }
   }

   return (
      <>
      <div className='contentTitle'>
         <h2>Gestão de Operadoras</h2>
      </div>
      <div className='grid-two'>
         <div className='grid-column'>
            <div className='column-title'>
               <h2>Modelo</h2>
            </div>
            <ButtonList
               filters={models}
               handleFilterClick={handleSelectModel}
               selectedFilter={model}
            />
         </div>
         <div className='grid-column'>
            <div className='column-title'>
               <h2>Operadoras</h2>
            </div>
            <div className='content'>
            {
               model && (
                  showAddOperatorForm ? (
                  <>
                  <div className='form-container'>
                     <ul className='form'>
                        <li>
                           <label>Nome da Operadora:</label>
                           <input className="inputBox" type="text" onChange={(e) => setOperatorName(e.target.value)}/>
                        </li>
                     </ul>
                  </div>
                  <ul className='button-list-hor'>
                     <li className='button-item'>
                        <button className="button" onClick={() => handleSubmeter()}>Gravar</button>
                     </li>
                     <li className='button-item'>
                        <button className="button" onClick={_ => setShowAddOperatorForm(false)}>Cancelar</button>
                     </li>
                  </ul>
                  </>
                  ) : (
                     
                     <ul className='button-list-ver'>
                        <li className='button-item'>
                           <button className='button' onClick={_ => setShowAddOperatorForm(true)}>Adicionar Operadora</button>
                        </li>
                     </ul>
                  )
               )
            }
            <hr></hr>
            {
               model && (
                  operators.length > 0 ? (
                     <>
                     {
                        operators.map(o => (
                           <ul className='card-list'>
                              <li className="card" key={o.name}>
                                 <ul className='button-list-hor'>
                                    <li className='button-item'>
                                       <button className="button" onClick={() => checkAccess(o._id,`/operator/${o._id}`)}>{o.name}</button>
                                    </li>
                                 </ul>
                              </li>
                           </ul>
                        ))
                     }
                     </>
                  ) : (
                     <h3>Ainda não existem operadoras...</h3>
                  )
               ) 
            }
            </div>
         </div>
      </div>
      </>
   )
}

export default GestOperators